.table {
  width: 100%;
  border-spacing: 0;
  th,
  td {
    text-align: left;
    border-bottom: solid 0.5px #6d757b;
    &.borderless {
      border-bottom: 0;
    }
    &.wmin {
      width: 1px;
    }
    &.right {
      text-align: right;
    }
    &.nowrap {
      white-space: nowrap;
    }
    &.nostyle {
      font-weight: normal;
      color: inherit;
      text-transform: none;
      font-style: normal;
    }
  }
  th {
    font-size: 11px;
    text-transform: uppercase;
    color: #f5b300;
    font-style: normal;
    padding: 2px 8px;
  }
  td {
    font-size: 12px;
    padding: 8px;
    font-style: italic;
  }
  tr {
    > th:first-child,
    > td:first-child {
      padding-left: 0;
    }
    > th:last-child,
    > td:last-child {
      padding-right: 0;
    }
    &:hover {
      td {
        // background-color: rgba(0, 0, 0, 0.5);
        background-color: #1f282f;
      }
    }
  }
  .btn {
    font-size: 12px;
    .icon {
      font-size: 10px;
    }
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
