@import url("https://fonts.googleapis.com/css?family=Roboto:400700&subset=latin-ext");
html {
    height: 100%;
}

.default,
.fx {
    @import "./themes/default";
}

.complex,
.adventure {
    @import "./themes/complex";
}

.pojdmedozoo {
    @import "./themes/pojdmedozoo";
}

.hhi {
    @import "./themes/hhi";
    body {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.brandLeftLogo {
    position: absolute;
    height: 70px;
    top: 10px;
    left: 10px;
    @media (max-height: 812px) {
        height: 60px;
        position: static;
        margin-top: 20px;
    }
}

.brandSmallLogo {
    height: 20px;
    margin-left: 8px;
    vertical-align: middle;
}

.cultureSwitch {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 20px;
    a {
        margin: 5px 10px;
        color: #999;
        cursor: pointer;
        &.active {
            color: white;
            text-decoration: underline;
        }
    }
    @media (max-height: 812px) {
        position: static;
    }
}

.culture__select-box {
    position: absolute;
    top: 1em;
    right: 1em;
    .select-box__option,
    input:checked+.select-box__input-text {
        display: flex;
        align-items: center;
    }
    input:checked+.select-box__input-text span {
        display: none;
        @media (min-height: 768px) {
            display: inline;
        }
    }
    .select-box__option,
    .select-box__value {
        span {
            margin-left: 4px;
        }
    }
}

.loaderSpinner {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    border: 2px solid transparent;
    border-top-color: #000000;
    box-shadow: 0 0 5px skyblue;
    border-radius: 50%;
    -webkit-animation: spin9 1.5s ease infinite;
    animation: spin9 1.5s ease infinite;
    &::before {
        content: "";
        position: absolute;
        top: 7px;
        right: 7px;
        bottom: 7px;
        left: 7px;
        border: 2px solid transparent;
        border-radius: 50%;
        border-top-color: #000000;
        box-shadow: 0 0 5px skyblue;
        -webkit-animation: spin9_x 1s linear infinite;
        animation: spin9_x 1s linear infinite;
    }
    &::after {
        content: "";
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        border: 2px solid transparent;
        border-radius: 50%;
        border-top-color: #000000;
        box-shadow: 0 0 5px skyblue;
        -webkit-animation: spin9 1.5s linear infinite;
        animation: spin9 1.5s linear infinite;
    }
}

@-webkit-keyframes spin9 {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin9 {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-webkit-keyframes spin9_x {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
    100% {
        -webkit-transform: rotateX(360deg);
        transform: rotateX(360deg);
    }
}

@keyframes spin9_x {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
    100% {
        -webkit-transform: rotateX(360deg);
        transform: rotateX(360deg);
        -webkit-transform: rotateX(360deg);
        transform: rotateX(360deg);
    }
}