html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: auto;
}

body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    background-color: #191e22;
    color: #fff;
    font-size: 15px;
}

#root {
    display: block;
    align-items: center;
    justify-content: center;
    @media (min-height: 768px) {
        display: flex;
    }
}

* {
    box-sizing: border-box;
}

.container {
    position: relative;
    box-sizing: border-box;
    width: 1024px;
    margin: 0 auto;
    padding: 0 20px;
    margin-bottom: 25px;
    &.container-small {
        width: 335px;
        > div {
            width: 100%;
        }
    }
    &.container-medium {
        width: 720px;
        > div {
            width: 100%;
        }
    }
    &.container-middle {
        padding: 60px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        box-sizing: border-box;
        width: 568px;
        max-width: 100%;
        background-color: #191209;
        > div {
            width: 335px;
            // position: relative;
        }
    }
    > div {
        // position: relative;
    }
}
