@import url(https://fonts.googleapis.com/css?family=Roboto:400700&subset=latin-ext);
.select-box {
  position: relative;
  display: block; }
  .select-box__current {
    position: relative;
    cursor: pointer;
    outline: none;
    padding-right: 1.25em; }
    .select-box__current:focus + .select-box__list {
      opacity: 1;
      -webkit-animation-name: none;
              animation-name: none; }
      .select-box__current:focus + .select-box__list .select-box__option {
        cursor: pointer; }
    .select-box__current:focus .select-box__icon {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .select-box__icon {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 0;
    height: 0;
    border-left: 0.35em solid transparent;
    border-right: 0.35em solid transparent;
    border-top: 0.35em solid currentColor;
    margin-top: -0.175em; }
  .select-box__value {
    display: flex;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .select-box__input {
    display: none; }
    .select-box__input:checked + .select-box__input-text {
      display: block; }
  .select-box__input-text {
    display: none;
    width: 100%;
    margin: 0; }
  .select-box__list {
    position: absolute;
    padding: 0;
    list-style: none;
    opacity: 0;
    -webkit-animation-name: SelectBoxHideList;
            animation-name: SelectBoxHideList;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
            animation-timing-function: step-start;
    z-index: 10000;
    background-color: black;
    white-space: nowrap;
    max-height: 100vh;
    overflow-y: auto; }
  .select-box__option {
    display: block;
    padding: 0.375em 0.75em; }
    .select-box__option:hover, .select-box__option:focus {
      color: #546c84;
      background-color: #fbfbfb; }
    .select-box__option--active {
      color: black;
      background-color: white; }
  .select-box--left > .select-box__list {
    left: 0; }
  .select-box--right > .select-box__list {
    right: 0; }

@-webkit-keyframes SelectBoxHideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0); } }

@keyframes SelectBoxHideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0); } }

html {
  height: 100%; }

.default html,
.default body,
.default #root,
.fx html,
.fx body,
.fx #root {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: auto; }

.default body,
.fx body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #191e22;
  color: #fff;
  font-size: 15px; }

.default #root,
.fx #root {
  display: block;
  align-items: center;
  justify-content: center; }
  @media (min-height: 768px) {
    .default #root,
    .fx #root {
      display: flex; } }

.default *,
.fx * {
  box-sizing: border-box; }

.default .container,
.fx .container {
  position: relative;
  box-sizing: border-box;
  width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 25px; }
  .default .container.container-small,
  .fx .container.container-small {
    width: 335px; }
    .default .container.container-small > div,
    .fx .container.container-small > div {
      width: 100%; }
  .default .container.container-medium,
  .fx .container.container-medium {
    width: 720px; }
    .default .container.container-medium > div,
    .fx .container.container-medium > div {
      width: 100%; }
  .default .container.container-middle,
  .fx .container.container-middle {
    padding: 60px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    box-sizing: border-box;
    width: 568px;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.75); }
    .default .container.container-middle > div,
    .fx .container.container-middle > div {
      width: 335px; }

.default .display-1,
.fx .display-1 {
  display: block;
  text-align: center;
  font-size: 64px;
  line-height: 75px;
  font-weight: 500;
  color: #3d90c2; }
  .default .display-1 small,
  .fx .display-1 small {
    color: #c0e2f7; }

.default .display-2,
.fx .display-2 {
  font-size: 24px;
  line-height: 24px; }

.default .text-right,
.fx .text-right {
  text-align: right; }

.default .table,
.fx .table {
  width: 100%;
  border-spacing: 0; }
  .default .table th,
  .default .table td,
  .fx .table th,
  .fx .table td {
    text-align: left;
    border-bottom: solid 0.5px #6d757b; }
    .default .table th.borderless,
    .default .table td.borderless,
    .fx .table th.borderless,
    .fx .table td.borderless {
      border-bottom: 0; }
    .default .table th.wmin,
    .default .table td.wmin,
    .fx .table th.wmin,
    .fx .table td.wmin {
      width: 1px; }
    .default .table th.right,
    .default .table td.right,
    .fx .table th.right,
    .fx .table td.right {
      text-align: right; }
    .default .table th.nowrap,
    .default .table td.nowrap,
    .fx .table th.nowrap,
    .fx .table td.nowrap {
      white-space: nowrap; }
    .default .table th.nostyle,
    .default .table td.nostyle,
    .fx .table th.nostyle,
    .fx .table td.nostyle {
      font-weight: normal;
      color: inherit;
      text-transform: none;
      font-style: normal; }
  .default .table th,
  .fx .table th {
    font-size: 11px;
    text-transform: uppercase;
    color: #f5b300;
    font-style: normal;
    padding: 2px 8px; }
  .default .table td,
  .fx .table td {
    font-size: 12px;
    padding: 8px;
    font-style: italic; }
  .default .table tr > th:first-child,
  .default .table tr > td:first-child,
  .fx .table tr > th:first-child,
  .fx .table tr > td:first-child {
    padding-left: 0; }
  .default .table tr > th:last-child,
  .default .table tr > td:last-child,
  .fx .table tr > th:last-child,
  .fx .table tr > td:last-child {
    padding-right: 0; }
  .default .table tr:hover td,
  .fx .table tr:hover td {
    background-color: #1f282f; }
  .default .table .btn,
  .fx .table .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    max-width: 100%; }
    .default .table .btn .icon,
    .fx .table .btn .icon {
      font-size: 10px; }

.default app-modal,
.fx app-modal {
  display: none; }

.default .modal_container,
.fx .modal_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5); }
  .default .modal_container .modal,
  .fx .modal_container .modal {
    width: 1024px;
    min-height: 400px;
    margin: 20vh auto;
    border: solid 1px #3d90c2;
    background-color: #000; }
    .default .modal_container .modal .modal_header,
    .fx .modal_container .modal .modal_header {
      font-size: 30px;
      line-height: 35px;
      font-weight: bold;
      font-style: italic;
      color: #fff;
      padding: 13px 26px; }
    .default .modal_container .modal .modal_content,
    .fx .modal_container .modal .modal_content {
      padding: 13px 26px; }
      .default .modal_container .modal .modal_content:after,
      .fx .modal_container .modal .modal_content:after {
        display: block;
        content: "";
        clear: both; }
    .default .modal_container .modal.modal--small,
    .fx .modal_container .modal.modal--small {
      width: 500px; }
    .default .modal_container .modal .modal_close,
    .fx .modal_container .modal .modal_close {
      cursor: pointer;
      display: inline-block;
      float: right;
      font-style: normal;
      padding: 0.5rem 1rem;
      margin-top: -0.5rem;
      margin-right: -1rem; }
      .default .modal_container .modal .modal_close:hover,
      .fx .modal_container .modal .modal_close:hover {
        background-color: rgba(0, 0, 0, 0.1); }

.default body.modal-open,
.fx body.modal-open {
  overflow: hidden; }

.default .sidebar,
.fx .sidebar {
  position: fixed;
  top: 60px;
  bottom: 60px;
  border: 1px solid #3d90c2;
  background-color: #1f282f;
  width: calc(100% - 200px);
  transition: all 0.2s ease-in-out;
  z-index: 101; }
  .default .sidebar .sidebar_handler,
  .fx .sidebar .sidebar_handler {
    position: absolute;
    left: 0;
    margin-top: 100px;
    cursor: pointer; }
    .default .sidebar .sidebar_handler > div,
    .fx .sidebar .sidebar_handler > div {
      padding: 25px 0; }
    .default .sidebar .sidebar_handler span,
    .fx .sidebar .sidebar_handler span {
      display: inline-block;
      word-wrap: break-word;
      box-sizing: border-box;
      padding: 10px;
      width: 25px;
      background-color: #1f282f; }
    .default .sidebar .sidebar_handler:hover span,
    .fx .sidebar .sidebar_handler:hover span {
      color: #3d90c2; }
  .default .sidebar .sidebar_content,
  .fx .sidebar .sidebar_content {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    overflow-y: auto; }
  .default .sidebar.sidebar--right,
  .fx .sidebar.sidebar--right {
    border-right-width: 0;
    left: 100%;
    min-width: 5px;
    margin-left: -5px; }
    .default .sidebar.sidebar--right .sidebar_handler,
    .fx .sidebar.sidebar--right .sidebar_handler {
      margin-left: -25px; }
      .default .sidebar.sidebar--right .sidebar_handler i:before, .default .sidebar.sidebar--right .sidebar_handler i:after,
      .fx .sidebar.sidebar--right .sidebar_handler i:before,
      .fx .sidebar.sidebar--right .sidebar_handler i:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent; }
      .default .sidebar.sidebar--right .sidebar_handler i:first-child:before, .default .sidebar.sidebar--right .sidebar_handler i:first-child:after,
      .fx .sidebar.sidebar--right .sidebar_handler i:first-child:before,
      .fx .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        top: 0;
        border-width: 0 0 25px 25px; }
      .default .sidebar.sidebar--right .sidebar_handler i:first-child:before,
      .fx .sidebar.sidebar--right .sidebar_handler i:first-child:before {
        border-bottom-color: #3d90c2;
        margin-top: -1px; }
      .default .sidebar.sidebar--right .sidebar_handler i:first-child:after,
      .fx .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        border-bottom-color: #1f282f; }
      .default .sidebar.sidebar--right .sidebar_handler i:last-child:before, .default .sidebar.sidebar--right .sidebar_handler i:last-child:after,
      .fx .sidebar.sidebar--right .sidebar_handler i:last-child:before,
      .fx .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        bottom: 0;
        border-width: 0 25px 25px 0; }
      .default .sidebar.sidebar--right .sidebar_handler i:last-child:before,
      .fx .sidebar.sidebar--right .sidebar_handler i:last-child:before {
        border-right-color: #3d90c2; }
      .default .sidebar.sidebar--right .sidebar_handler i:last-child:after,
      .fx .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        border-right-color: #1f282f;
        left: 1px; }
      .default .sidebar.sidebar--right .sidebar_handler span,
      .fx .sidebar.sidebar--right .sidebar_handler span {
        border-left: 1px solid #3d90c2;
        margin-top: -1px; }
  .default .sidebar.sidebar--active,
  .fx .sidebar.sidebar--active {
    left: 200px;
    margin-left: 0; }

.default .sidebar-back,
.fx .sidebar-back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; }

.default body.sidebar-open,
.fx body.sidebar-open {
  overflow: hidden; }

.default .panel,
.fx .panel {
  box-sizing: border-box;
  padding: 10px 25px; }
  .default .panel.panel--collection,
  .fx .panel.panel--collection {
    border-radius: 3px;
    box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
    margin-top: 5px; }
    .default .panel.panel--collection > header,
    .fx .panel.panel--collection > header {
      color: #3d90c2;
      line-height: 20px;
      margin-bottom: 8px; }
  .default .panel.panel--uploader,
  .fx .panel.panel--uploader {
    color: #fff;
    border-radius: 3px;
    padding: 15px 20px; }
    .default .panel.panel--uploader .panel_container,
    .fx .panel.panel--uploader .panel_container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: dashed 2px #14577e;
      font-size: 16px;
      line-height: 19px; }
      .default .panel.panel--uploader .panel_container > .panel_button,
      .fx .panel.panel--uploader .panel_container > .panel_button {
        width: 100%;
        text-align: center;
        cursor: pointer; }
        .default .panel.panel--uploader .panel_container > .panel_button input,
        .fx .panel.panel--uploader .panel_container > .panel_button input {
          position: absolute;
          width: 1px;
          height: 1px;
          opacity: 0; }
        .default .panel.panel--uploader .panel_container > .panel_button .display-2,
        .fx .panel.panel--uploader .panel_container > .panel_button .display-2 {
          line-height: 168px; }
      .default .panel.panel--uploader .panel_container:hover,
      .fx .panel.panel--uploader .panel_container:hover {
        background-color: #000; }

.default .cards:after,
.fx .cards:after {
  content: "";
  display: block;
  clear: both; }

.default .cards .card,
.fx .cards .card {
  display: block;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  padding: 25px;
  width: calc(50% - 12.5px);
  float: left;
  margin-bottom: 30px;
  cursor: pointer; }
  .default .cards .card:nth-child(2n),
  .fx .cards .card:nth-child(2n) {
    margin-left: 25px; }
  .default .cards .card .card_title,
  .fx .cards .card .card_title {
    color: #3d90c2;
    margin: 0;
    font-size: 20px;
    line-height: 24px; }
  .default .cards .card:hover,
  .fx .cards .card:hover {
    box-shadow: none;
    outline: 1px solid #3d90c2; }

.default .collection,
.fx .collection {
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #3d90c2;
  background-color: #000;
  margin-top: 10px; }
  .default .collection > header,
  .fx .collection > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: #3d90c2;
    height: 40px;
    padding: 8px 8px;
    line-height: 24px; }
    .default .collection > header:hover,
    .fx .collection > header:hover {
      background-color: #1f282f; }
  .default .collection .collection_info,
  .fx .collection .collection_info {
    font-size: 15px;
    color: #979797;
    margin: 0 10px; }
  .default .collection .collection_content,
  .fx .collection .collection_content {
    display: none;
    padding: 8px 0;
    margin: 0 8px; }
  .default .collection .collection_actions,
  .fx .collection .collection_actions {
    display: inline-block;
    width: 284px;
    text-align: right; }
  .default .collection .collection_toggle,
  .fx .collection .collection_toggle {
    vertical-align: middle;
    margin: 0 4px 0 12px;
    cursor: pointer; }
    .default .collection .collection_toggle.collection_toggle--opened,
    .fx .collection .collection_toggle.collection_toggle--opened {
      display: none; }
    .default .collection .collection_toggle.collection_toggle--closed,
    .fx .collection .collection_toggle.collection_toggle--closed {
      display: inline-block; }
  .default .collection.collection--active > header,
  .fx .collection.collection--active > header {
    border-bottom: .5px solid #979797; }
  .default .collection.collection--active .collection_content,
  .fx .collection.collection--active .collection_content {
    display: block; }
  .default .collection.collection--active .collection_toggle.collection_toggle--opened,
  .fx .collection.collection--active .collection_toggle.collection_toggle--opened {
    display: inline-block; }
  .default .collection.collection--active .collection_toggle.collection_toggle--closed,
  .fx .collection.collection--active .collection_toggle.collection_toggle--closed {
    display: none; }
  .default .collection:first-child,
  .fx .collection:first-child {
    margin-top: 0; }

.default .badge,
.fx .badge {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 59px;
  height: 16px;
  border-radius: 1.5px;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  margin: 0 4px;
  font-style: normal;
  transition: all 0.2s ease-in-out; }
  .default .badge.badge--gray,
  .fx .badge.badge--gray {
    background-color: #4a4a4a;
    color: #fff;
    border: 0; }
  .default .badge.badge--orange,
  .fx .badge.badge--orange {
    background-color: #e29d28;
    color: #fff;
    border: 0; }
  .default .badge.badge--blue,
  .fx .badge.badge--blue {
    background-color: #3d90c2;
    color: #fff;
    border: 0; }
  .default .badge.badge--green,
  .fx .badge.badge--green {
    background-color: #8bc34a;
    color: #fff;
    border: 0; }
  .default .badge.badge--red,
  .fx .badge.badge--red {
    background-color: #800303;
    color: #fff;
    border: 0; }
  .default .badge[data-index]:after,
  .fx .badge[data-index]:after {
    content: attr(data-index);
    display: inline-block;
    position: absolute;
    min-width: 12px;
    height: 12px;
    border-radius: 1.5px;
    background-color: #d0021b;
    right: -4px;
    top: -4px;
    line-height: 12px;
    font-size: 9px; }

.default .btn,
.fx .btn {
  display: inline-block;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  border-radius: 2px;
  padding: 6px 10px;
  line-height: 1;
  font-style: normal;
  white-space: nowrap;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .default .btn span,
  .fx .btn span {
    margin-left: 8px; }
    .default .btn span:first-child,
    .fx .btn span:first-child {
      margin-left: 0; }
  .default .btn .icon:before,
  .fx .btn .icon:before {
    vertical-align: middle; }
  .default .btn:hover,
  .fx .btn:hover {
    border-color: #fff;
    color: #fff; }
  .default .btn:disabled,
  .fx .btn:disabled {
    opacity: 0.5;
    cursor: default; }
  .default .btn.btn--green,
  .fx .btn.btn--green {
    color: #7ed321;
    border-color: #7ed321; }
    .default .btn.btn--green:hover,
    .fx .btn.btn--green:hover {
      background-color: #8bc34a;
      border: solid 1px #7ed321;
      color: #3c763d; }
    .default .btn.btn--green:disabled,
    .fx .btn.btn--green:disabled {
      border-color: #fff;
      color: #fff; }
      .default .btn.btn--green:disabled:hover,
      .fx .btn.btn--green:disabled:hover {
        background-color: transparent; }
  .default .btn.btn--transparent,
  .fx .btn.btn--transparent {
    border-color: transparent; }
  .default .btn.btn--lg,
  .fx .btn.btn--lg {
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 15px 30px;
    min-width: 184px; }
    .default .btn.btn--lg:disabled,
    .fx .btn.btn--lg:disabled {
      border: solid 1px #b8b8b8 !important;
      color: #b8b8b8 !important;
      background-color: transparent !important; }
  .default .btn.btn--sm,
  .fx .btn.btn--sm {
    padding: 2px 4px; }

.default .link,
.fx .link {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #fff; }
  .default .link .icon,
  .fx .link .icon {
    font-size: 75%;
    margin-left: 5px; }
  .default .link:hover,
  .fx .link:hover {
    border-bottom: 1px solid #fff; }
  .default .link.link--green,
  .fx .link.link--green {
    color: #7ed321; }
    .default .link.link--green:hover,
    .fx .link.link--green:hover {
      border-color: #7ed321; }
  .default .link.link--gray,
  .fx .link.link--gray {
    color: #b8b8b8; }
    .default .link.link--gray:hover,
    .fx .link.link--gray:hover {
      border-color: #b8b8b8; }

.default div.form,
.default form.form,
.fx div.form,
.fx form.form {
  font-size: 16px; }
  .default div.form .btn:hover,
  .default form.form .btn:hover,
  .fx div.form .btn:hover,
  .fx form.form .btn:hover {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8); }
  .default div.form h3.title,
  .default form.form h3.title,
  .fx div.form h3.title,
  .fx form.form h3.title {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    margin: 30px 0;
    text-align: center;
    text-transform: uppercase; }
  .default div.form h4.title,
  .default form.form h4.title,
  .fx div.form h4.title,
  .fx form.form h4.title {
    color: #3d90c2;
    font-size: 18px;
    font-weight: normal;
    text-align: center; }
  .default div.form .form-group,
  .default form.form .form-group,
  .fx div.form .form-group,
  .fx form.form .form-group {
    font-size: 14px;
    margin-bottom: 15px; }
    .default div.form .form-group label,
    .default form.form .form-group label,
    .fx div.form .form-group label,
    .fx form.form .form-group label {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #6a6a6a;
      margin-bottom: 5px; }
    .default div.form .form-group input,
    .default form.form .form-group input,
    .fx div.form .form-group input,
    .fx form.form .form-group input {
      display: block;
      width: 100%;
      border: 1px solid #6a6a67;
      border-radius: 3px;
      background-color: transparent;
      line-height: 37px;
      color: #fff;
      font-size: 16px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 50px;
      max-width: 100%; }
    .default div.form .form-group input[type="checkbox"],
    .default form.form .form-group input[type="checkbox"],
    .fx div.form .form-group input[type="checkbox"],
    .fx form.form .form-group input[type="checkbox"] {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5em;
      vertical-align: middle; }
      .default div.form .form-group input[type="checkbox"] + label,
      .default form.form .form-group input[type="checkbox"] + label,
      .fx div.form .form-group input[type="checkbox"] + label,
      .fx form.form .form-group input[type="checkbox"] + label {
        display: inline-block; }
        .default div.form .form-group input[type="checkbox"] + label a,
        .default form.form .form-group input[type="checkbox"] + label a,
        .fx div.form .form-group input[type="checkbox"] + label a,
        .fx form.form .form-group input[type="checkbox"] + label a {
          color: #3d90c2 !important; }
    .default div.form .form-group span,
    .default div.form .form-group small,
    .default form.form .form-group span,
    .default form.form .form-group small,
    .fx div.form .form-group span,
    .fx div.form .form-group small,
    .fx form.form .form-group span,
    .fx form.form .form-group small {
      display: block;
      margin-top: 5px; }
  .default div.form .btn,
  .default form.form .btn,
  .fx div.form .btn,
  .fx form.form .btn {
    width: 100%;
    padding: 10px 20px;
    line-height: 30px;
    border-radius: 3px;
    text-transform: uppercase;
    background-image: linear-gradient(98deg, #8ec5e6, #3d90c2);
    color: #fff;
    border: 0;
    text-align: center;
    font-size: 16px;
    height: 50px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .default div.form .btn.btn-secondary,
    .default form.form .btn.btn-secondary,
    .fx div.form .btn.btn-secondary,
    .fx form.form .btn.btn-secondary {
      color: #8ec5e6;
      border: 1px solid #8ec5e6;
      background-color: transparent;
      background-image: none; }
  .default div.form hr,
  .default form.form hr,
  .fx div.form hr,
  .fx form.form hr {
    background: none;
    border: 0;
    border-bottom: 1px dashed #6a6a6a;
    margin-bottom: 8px; }
    .default div.form hr.distance,
    .default form.form hr.distance,
    .fx div.form hr.distance,
    .fx form.form hr.distance {
      margin-top: 30px; }
  .default div.form .link,
  .default form.form .link,
  .fx div.form .link,
  .fx form.form .link {
    font-size: 16px;
    color: #6a6a6a;
    text-decoration: none; }
    .default div.form .link:hover,
    .default form.form .link:hover,
    .fx div.form .link:hover,
    .fx form.form .link:hover {
      border-bottom: 0;
      text-decoration: underline; }
    .default div.form .link.link--primary,
    .default form.form .link.link--primary,
    .fx div.form .link.link--primary,
    .fx form.form .link.link--primary {
      color: #3d90c2; }
    .default div.form .link.hoverable:hover,
    .default form.form .link.hoverable:hover,
    .fx div.form .link.hoverable:hover,
    .fx form.form .link.hoverable:hover {
      color: #3d90c2;
      text-decoration: underline; }
  .default div.form .error,
  .default form.form .error,
  .fx div.form .error,
  .fx form.form .error {
    color: #d0021b !important; }
    .default div.form .error a,
    .default form.form .error a,
    .fx div.form .error a,
    .fx form.form .error a {
      color: #d0021b !important; }
    .default div.form .error input,
    .default div.form .error select,
    .default div.form .error textarea,
    .default form.form .error input,
    .default form.form .error select,
    .default form.form .error textarea,
    .fx div.form .error input,
    .fx div.form .error select,
    .fx div.form .error textarea,
    .fx form.form .error input,
    .fx form.form .error select,
    .fx form.form .error textarea {
      border-color: #d0021b; }
  .default div.form .description,
  .default form.form .description,
  .fx div.form .description,
  .fx form.form .description {
    margin: 30px 0;
    color: #6a6a6a; }
    .default div.form .description ul,
    .default div.form .description ol,
    .default form.form .description ul,
    .default form.form .description ol,
    .fx div.form .description ul,
    .fx div.form .description ol,
    .fx form.form .description ul,
    .fx form.form .description ol {
      padding-left: 0;
      font-size: 14px; }
      .default div.form .description ul li,
      .default div.form .description ol li,
      .default form.form .description ul li,
      .default form.form .description ol li,
      .fx div.form .description ul li,
      .fx div.form .description ol li,
      .fx form.form .description ul li,
      .fx form.form .description ol li {
        list-style-position: inside;
        margin: 5px; }
  .default div.form footer,
  .default form.form footer,
  .fx div.form footer,
  .fx form.form footer {
    font-size: 12px;
    color: #6a6a6a; }
    .default div.form footer p,
    .default form.form footer p,
    .fx div.form footer p,
    .fx form.form footer p {
      margin-bottom: 1px;
      margin-top: 2px; }
    .default div.form footer a,
    .default form.form footer a,
    .fx div.form footer a,
    .fx form.form footer a {
      color: #3d90c2; }

.default .progress,
.fx .progress {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  height: 10px;
  border-radius: 1.5px;
  border: solid 1px #fff; }
  .default .progress .progress_handler,
  .fx .progress .progress_handler {
    position: absolute;
    display: block;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 100%; }
  .default .progress.progress--green,
  .fx .progress.progress--green {
    border-color: #7ed321; }
    .default .progress.progress--green .progress_handler,
    .fx .progress.progress--green .progress_handler {
      background-color: #7ed321; }
  .default .progress.progress--red,
  .fx .progress.progress--red {
    border-color: #d0021b; }
    .default .progress.progress--red .progress_handler,
    .fx .progress.progress--red .progress_handler {
      background-color: #d0021b; }

.default .tooltip__closer,
.fx .tooltip__closer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999; }

.default .ng-tooltip,
.fx .ng-tooltip {
  position: fixed;
  max-width: 450px;
  z-index: 10000;
  opacity: 0;
  border: 1px solid #7ed321;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #191e22;
  padding: 10px 15px;
  color: #fff;
  font-size: 10px; }
  .default .ng-tooltip h3,
  .fx .ng-tooltip h3 {
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0 0 5px 0; }
  .default .ng-tooltip hr,
  .fx .ng-tooltip hr {
    height: 0px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #6d757b; }
  .default .ng-tooltip .line,
  .fx .ng-tooltip .line {
    width: 725px;
    padding: 3px 0; }
    .default .ng-tooltip .line .key,
    .fx .ng-tooltip .line .key {
      display: inline-block;
      width: 75px;
      color: #f5b300;
      float: left; }
      .default .ng-tooltip .line .key:after,
      .fx .ng-tooltip .line .key:after {
        content: ":"; }
    .default .ng-tooltip .line .value,
    .fx .ng-tooltip .line .value {
      display: inline-block;
      width: 350px; }
      .default .ng-tooltip .line .value .info,
      .fx .ng-tooltip .line .value .info {
        display: inline-block;
        float: right;
        width: 80px; }
        .default .ng-tooltip .line .value .info.info--green,
        .fx .ng-tooltip .line .value .info.info--green {
          color: #7ed321; }
        .default .ng-tooltip .line .value .info.info--yellow,
        .fx .ng-tooltip .line .value .info.info--yellow {
          color: #f8e71c; }

.default .ng-tooltip-show,
.fx .ng-tooltip-show {
  opacity: 0.95; }

.default .ml-1,
.fx .ml-1 {
  margin-left: 10px !important; }

.default .ml-2,
.fx .ml-2 {
  margin-left: 20px !important; }

.default .mt-2,
.fx .mt-2 {
  margin-top: 20px !important; }

.default .mb-1,
.fx .mb-1 {
  margin-bottom: 10px !important; }

.default .mb-2,
.fx .mb-2 {
  margin-bottom: 20px !important; }

.default .mb-3,
.fx .mb-3 {
  margin-bottom: 30px !important; }

.default .mb-big,
.fx .mb-big {
  margin-bottom: 200px !important; }
  @media (max-width: 768px) {
    .default .mb-big,
    .fx .mb-big {
      margin-bottom: 80px !important; } }

.default .text-center,
.fx .text-center {
  text-align: center; }

.default .color-error,
.fx .color-error {
  color: #d0021b; }

.default .nowrap,
.fx .nowrap {
  white-space: nowrap; }

.default .w150,
.fx .w150 {
  width: 150px; }

.default .brand-logo,
.fx .brand-logo {
  display: inline-block;
  width: 38px;
  height: 38px; }
  .default .brand-logo span,
  .fx .brand-logo span {
    display: block;
    background-color: #49afd9;
    border-radius: 3px;
    width: 14px;
    height: 14px;
    margin: 2.5px;
    float: left; }
    .default .brand-logo span:first-child,
    .fx .brand-logo span:first-child {
      background-color: #37deeb; }

.default .brand-icon,
.fx .brand-icon {
  margin-right: 1rem; }

.default .brand-title,
.fx .brand-title {
  display: inline-block;
  font-size: 36px;
  letter-spacing: 2px;
  color: #3d90c2;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 38px;
  margin: 0;
  vertical-align: top;
  position: relative;
  top: -1px; }

.default .brand-title-description,
.fx .brand-title-description {
  font-size: 20px;
  color: #6a6a6a;
  margin: 5px 0; }

.default .dropdown-fog,
.fx .dropdown-fog {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 199; }

.default .dropdown-container,
.fx .dropdown-container {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .default .dropdown-container :first-child,
  .fx .dropdown-container :first-child {
    cursor: pointer; }
  .default .dropdown-container.dropdown--active .dropdown,
  .fx .dropdown-container.dropdown--active .dropdown {
    display: block !important; }
  .default .dropdown-container .dropdown-button,
  .default .dropdown-container .dropdown-opener,
  .fx .dropdown-container .dropdown-button,
  .fx .dropdown-container .dropdown-opener {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .default .dropdown-container .dropdown-button:hover,
    .default .dropdown-container .dropdown-opener:hover,
    .fx .dropdown-container .dropdown-button:hover,
    .fx .dropdown-container .dropdown-opener:hover {
      color: #3d90c2; }
  .default .dropdown-container .dropdown,
  .fx .dropdown-container .dropdown {
    position: absolute;
    left: 0;
    top: 30px;
    border: 1px solid #3d90c2;
    background-color: #1f282f;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    display: none;
    z-index: 201;
    min-width: 200px;
    border-top: 0; }
    .default .dropdown-container .dropdown.dropdown--right,
    .fx .dropdown-container .dropdown.dropdown--right {
      left: auto;
      right: 0;
      margin-right: -26px; }
    .default .dropdown-container .dropdown .dropdown-header,
    .fx .dropdown-container .dropdown .dropdown-header {
      display: block;
      font-style: italic;
      color: #3d90c2;
      margin: 15px 20px 0 20px; }
    .default .dropdown-container .dropdown .dropdown-item,
    .fx .dropdown-container .dropdown .dropdown-item {
      margin: 3px 20px; }
    .default .dropdown-container .dropdown .dropdown-separator,
    .fx .dropdown-container .dropdown .dropdown-separator {
      display: block;
      border-bottom: 1px solid #353e46;
      margin: 15px 10px; }
    .default .dropdown-container .dropdown .dropdown-button,
    .fx .dropdown-container .dropdown .dropdown-button {
      display: block;
      background-color: #2b383e;
      text-align: center;
      font-style: italic;
      text-decoration: none !important;
      padding: 8px 16px;
      margin-top: 15px; }
    .default .dropdown-container .dropdown a,
    .fx .dropdown-container .dropdown a {
      color: inherit;
      text-decoration: none;
      cursor: pointer; }
      .default .dropdown-container .dropdown a:hover,
      .fx .dropdown-container .dropdown a:hover {
        text-decoration: underline; }

.default #toast-container > div,
.fx #toast-container > div {
  border-radius: 5px;
  opacity: 1;
  box-shadow: none !important; }

.default .toast-close-button,
.fx .toast-close-button {
  -webkit-text-shadow: none;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 0.15em;
  top: 0; }
  .default .toast-close-button:hover,
  .fx .toast-close-button:hover {
    opacity: 0.8; }

.default .toast-title,
.fx .toast-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px; }

.default .toast-message,
.fx .toast-message {
  height: 14px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic; }

.complex html,
.complex body,
.complex #root,
.adventure html,
.adventure body,
.adventure #root {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: auto; }

.complex body,
.adventure body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #191e22;
  color: #fff;
  font-size: 15px; }

.complex #root,
.adventure #root {
  display: block;
  align-items: center;
  justify-content: center; }
  @media (min-height: 768px) {
    .complex #root,
    .adventure #root {
      display: flex; } }

.complex *,
.adventure * {
  box-sizing: border-box; }

.complex .container,
.adventure .container {
  position: relative;
  box-sizing: border-box;
  width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 25px; }
  .complex .container.container-small,
  .adventure .container.container-small {
    width: 335px; }
    .complex .container.container-small > div,
    .adventure .container.container-small > div {
      width: 100%; }
  .complex .container.container-medium,
  .adventure .container.container-medium {
    width: 720px; }
    .complex .container.container-medium > div,
    .adventure .container.container-medium > div {
      width: 100%; }
  .complex .container.container-middle,
  .adventure .container.container-middle {
    padding: 60px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    box-sizing: border-box;
    width: 568px;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.75); }
    .complex .container.container-middle > div,
    .adventure .container.container-middle > div {
      width: 335px; }

.complex .display-1,
.adventure .display-1 {
  display: block;
  text-align: center;
  font-size: 64px;
  line-height: 75px;
  font-weight: 500;
  color: #3d90c2; }
  .complex .display-1 small,
  .adventure .display-1 small {
    color: #c0e2f7; }

.complex .display-2,
.adventure .display-2 {
  font-size: 24px;
  line-height: 24px; }

.complex .text-right,
.adventure .text-right {
  text-align: right; }

.complex .table,
.adventure .table {
  width: 100%;
  border-spacing: 0; }
  .complex .table th,
  .complex .table td,
  .adventure .table th,
  .adventure .table td {
    text-align: left;
    border-bottom: solid 0.5px #6d757b; }
    .complex .table th.borderless,
    .complex .table td.borderless,
    .adventure .table th.borderless,
    .adventure .table td.borderless {
      border-bottom: 0; }
    .complex .table th.wmin,
    .complex .table td.wmin,
    .adventure .table th.wmin,
    .adventure .table td.wmin {
      width: 1px; }
    .complex .table th.right,
    .complex .table td.right,
    .adventure .table th.right,
    .adventure .table td.right {
      text-align: right; }
    .complex .table th.nowrap,
    .complex .table td.nowrap,
    .adventure .table th.nowrap,
    .adventure .table td.nowrap {
      white-space: nowrap; }
    .complex .table th.nostyle,
    .complex .table td.nostyle,
    .adventure .table th.nostyle,
    .adventure .table td.nostyle {
      font-weight: normal;
      color: inherit;
      text-transform: none;
      font-style: normal; }
  .complex .table th,
  .adventure .table th {
    font-size: 11px;
    text-transform: uppercase;
    color: #f5b300;
    font-style: normal;
    padding: 2px 8px; }
  .complex .table td,
  .adventure .table td {
    font-size: 12px;
    padding: 8px;
    font-style: italic; }
  .complex .table tr > th:first-child,
  .complex .table tr > td:first-child,
  .adventure .table tr > th:first-child,
  .adventure .table tr > td:first-child {
    padding-left: 0; }
  .complex .table tr > th:last-child,
  .complex .table tr > td:last-child,
  .adventure .table tr > th:last-child,
  .adventure .table tr > td:last-child {
    padding-right: 0; }
  .complex .table tr:hover td,
  .adventure .table tr:hover td {
    background-color: #1f282f; }
  .complex .table .btn,
  .adventure .table .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    max-width: 100%; }
    .complex .table .btn .icon,
    .adventure .table .btn .icon {
      font-size: 10px; }

.complex app-modal,
.adventure app-modal {
  display: none; }

.complex .modal_container,
.adventure .modal_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5); }
  .complex .modal_container .modal,
  .adventure .modal_container .modal {
    width: 1024px;
    min-height: 400px;
    margin: 20vh auto;
    border: solid 1px #3d90c2;
    background-color: #000; }
    .complex .modal_container .modal .modal_header,
    .adventure .modal_container .modal .modal_header {
      font-size: 30px;
      line-height: 35px;
      font-weight: bold;
      font-style: italic;
      color: #fff;
      padding: 13px 26px; }
    .complex .modal_container .modal .modal_content,
    .adventure .modal_container .modal .modal_content {
      padding: 13px 26px; }
      .complex .modal_container .modal .modal_content:after,
      .adventure .modal_container .modal .modal_content:after {
        display: block;
        content: "";
        clear: both; }
    .complex .modal_container .modal.modal--small,
    .adventure .modal_container .modal.modal--small {
      width: 500px; }
    .complex .modal_container .modal .modal_close,
    .adventure .modal_container .modal .modal_close {
      cursor: pointer;
      display: inline-block;
      float: right;
      font-style: normal;
      padding: 0.5rem 1rem;
      margin-top: -0.5rem;
      margin-right: -1rem; }
      .complex .modal_container .modal .modal_close:hover,
      .adventure .modal_container .modal .modal_close:hover {
        background-color: rgba(0, 0, 0, 0.1); }

.complex body.modal-open,
.adventure body.modal-open {
  overflow: hidden; }

.complex .sidebar,
.adventure .sidebar {
  position: fixed;
  top: 60px;
  bottom: 60px;
  border: 1px solid #3d90c2;
  background-color: #1f282f;
  width: calc(100% - 200px);
  transition: all 0.2s ease-in-out;
  z-index: 101; }
  .complex .sidebar .sidebar_handler,
  .adventure .sidebar .sidebar_handler {
    position: absolute;
    left: 0;
    margin-top: 100px;
    cursor: pointer; }
    .complex .sidebar .sidebar_handler > div,
    .adventure .sidebar .sidebar_handler > div {
      padding: 25px 0; }
    .complex .sidebar .sidebar_handler span,
    .adventure .sidebar .sidebar_handler span {
      display: inline-block;
      word-wrap: break-word;
      box-sizing: border-box;
      padding: 10px;
      width: 25px;
      background-color: #1f282f; }
    .complex .sidebar .sidebar_handler:hover span,
    .adventure .sidebar .sidebar_handler:hover span {
      color: #3d90c2; }
  .complex .sidebar .sidebar_content,
  .adventure .sidebar .sidebar_content {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    overflow-y: auto; }
  .complex .sidebar.sidebar--right,
  .adventure .sidebar.sidebar--right {
    border-right-width: 0;
    left: 100%;
    min-width: 5px;
    margin-left: -5px; }
    .complex .sidebar.sidebar--right .sidebar_handler,
    .adventure .sidebar.sidebar--right .sidebar_handler {
      margin-left: -25px; }
      .complex .sidebar.sidebar--right .sidebar_handler i:before, .complex .sidebar.sidebar--right .sidebar_handler i:after,
      .adventure .sidebar.sidebar--right .sidebar_handler i:before,
      .adventure .sidebar.sidebar--right .sidebar_handler i:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent; }
      .complex .sidebar.sidebar--right .sidebar_handler i:first-child:before, .complex .sidebar.sidebar--right .sidebar_handler i:first-child:after,
      .adventure .sidebar.sidebar--right .sidebar_handler i:first-child:before,
      .adventure .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        top: 0;
        border-width: 0 0 25px 25px; }
      .complex .sidebar.sidebar--right .sidebar_handler i:first-child:before,
      .adventure .sidebar.sidebar--right .sidebar_handler i:first-child:before {
        border-bottom-color: #3d90c2;
        margin-top: -1px; }
      .complex .sidebar.sidebar--right .sidebar_handler i:first-child:after,
      .adventure .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        border-bottom-color: #1f282f; }
      .complex .sidebar.sidebar--right .sidebar_handler i:last-child:before, .complex .sidebar.sidebar--right .sidebar_handler i:last-child:after,
      .adventure .sidebar.sidebar--right .sidebar_handler i:last-child:before,
      .adventure .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        bottom: 0;
        border-width: 0 25px 25px 0; }
      .complex .sidebar.sidebar--right .sidebar_handler i:last-child:before,
      .adventure .sidebar.sidebar--right .sidebar_handler i:last-child:before {
        border-right-color: #3d90c2; }
      .complex .sidebar.sidebar--right .sidebar_handler i:last-child:after,
      .adventure .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        border-right-color: #1f282f;
        left: 1px; }
      .complex .sidebar.sidebar--right .sidebar_handler span,
      .adventure .sidebar.sidebar--right .sidebar_handler span {
        border-left: 1px solid #3d90c2;
        margin-top: -1px; }
  .complex .sidebar.sidebar--active,
  .adventure .sidebar.sidebar--active {
    left: 200px;
    margin-left: 0; }

.complex .sidebar-back,
.adventure .sidebar-back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; }

.complex body.sidebar-open,
.adventure body.sidebar-open {
  overflow: hidden; }

.complex .panel,
.adventure .panel {
  box-sizing: border-box;
  padding: 10px 25px; }
  .complex .panel.panel--collection,
  .adventure .panel.panel--collection {
    border-radius: 3px;
    box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
    margin-top: 5px; }
    .complex .panel.panel--collection > header,
    .adventure .panel.panel--collection > header {
      color: #3d90c2;
      line-height: 20px;
      margin-bottom: 8px; }
  .complex .panel.panel--uploader,
  .adventure .panel.panel--uploader {
    color: #fff;
    border-radius: 3px;
    padding: 15px 20px; }
    .complex .panel.panel--uploader .panel_container,
    .adventure .panel.panel--uploader .panel_container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: dashed 2px #14577e;
      font-size: 16px;
      line-height: 19px; }
      .complex .panel.panel--uploader .panel_container > .panel_button,
      .adventure .panel.panel--uploader .panel_container > .panel_button {
        width: 100%;
        text-align: center;
        cursor: pointer; }
        .complex .panel.panel--uploader .panel_container > .panel_button input,
        .adventure .panel.panel--uploader .panel_container > .panel_button input {
          position: absolute;
          width: 1px;
          height: 1px;
          opacity: 0; }
        .complex .panel.panel--uploader .panel_container > .panel_button .display-2,
        .adventure .panel.panel--uploader .panel_container > .panel_button .display-2 {
          line-height: 168px; }
      .complex .panel.panel--uploader .panel_container:hover,
      .adventure .panel.panel--uploader .panel_container:hover {
        background-color: #000; }

.complex .cards:after,
.adventure .cards:after {
  content: "";
  display: block;
  clear: both; }

.complex .cards .card,
.adventure .cards .card {
  display: block;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  padding: 25px;
  width: calc(50% - 12.5px);
  float: left;
  margin-bottom: 30px;
  cursor: pointer; }
  .complex .cards .card:nth-child(2n),
  .adventure .cards .card:nth-child(2n) {
    margin-left: 25px; }
  .complex .cards .card .card_title,
  .adventure .cards .card .card_title {
    color: #3d90c2;
    margin: 0;
    font-size: 20px;
    line-height: 24px; }
  .complex .cards .card:hover,
  .adventure .cards .card:hover {
    box-shadow: none;
    outline: 1px solid #3d90c2; }

.complex .collection,
.adventure .collection {
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #3d90c2;
  background-color: #000;
  margin-top: 10px; }
  .complex .collection > header,
  .adventure .collection > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: #3d90c2;
    height: 40px;
    padding: 8px 8px;
    line-height: 24px; }
    .complex .collection > header:hover,
    .adventure .collection > header:hover {
      background-color: #1f282f; }
  .complex .collection .collection_info,
  .adventure .collection .collection_info {
    font-size: 15px;
    color: #979797;
    margin: 0 10px; }
  .complex .collection .collection_content,
  .adventure .collection .collection_content {
    display: none;
    padding: 8px 0;
    margin: 0 8px; }
  .complex .collection .collection_actions,
  .adventure .collection .collection_actions {
    display: inline-block;
    width: 284px;
    text-align: right; }
  .complex .collection .collection_toggle,
  .adventure .collection .collection_toggle {
    vertical-align: middle;
    margin: 0 4px 0 12px;
    cursor: pointer; }
    .complex .collection .collection_toggle.collection_toggle--opened,
    .adventure .collection .collection_toggle.collection_toggle--opened {
      display: none; }
    .complex .collection .collection_toggle.collection_toggle--closed,
    .adventure .collection .collection_toggle.collection_toggle--closed {
      display: inline-block; }
  .complex .collection.collection--active > header,
  .adventure .collection.collection--active > header {
    border-bottom: .5px solid #979797; }
  .complex .collection.collection--active .collection_content,
  .adventure .collection.collection--active .collection_content {
    display: block; }
  .complex .collection.collection--active .collection_toggle.collection_toggle--opened,
  .adventure .collection.collection--active .collection_toggle.collection_toggle--opened {
    display: inline-block; }
  .complex .collection.collection--active .collection_toggle.collection_toggle--closed,
  .adventure .collection.collection--active .collection_toggle.collection_toggle--closed {
    display: none; }
  .complex .collection:first-child,
  .adventure .collection:first-child {
    margin-top: 0; }

.complex .badge,
.adventure .badge {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 59px;
  height: 16px;
  border-radius: 1.5px;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  margin: 0 4px;
  font-style: normal;
  transition: all 0.2s ease-in-out; }
  .complex .badge.badge--gray,
  .adventure .badge.badge--gray {
    background-color: #4a4a4a;
    color: #fff;
    border: 0; }
  .complex .badge.badge--orange,
  .adventure .badge.badge--orange {
    background-color: #e29d28;
    color: #fff;
    border: 0; }
  .complex .badge.badge--blue,
  .adventure .badge.badge--blue {
    background-color: #3d90c2;
    color: #fff;
    border: 0; }
  .complex .badge.badge--green,
  .adventure .badge.badge--green {
    background-color: #8bc34a;
    color: #fff;
    border: 0; }
  .complex .badge.badge--red,
  .adventure .badge.badge--red {
    background-color: #800303;
    color: #fff;
    border: 0; }
  .complex .badge[data-index]:after,
  .adventure .badge[data-index]:after {
    content: attr(data-index);
    display: inline-block;
    position: absolute;
    min-width: 12px;
    height: 12px;
    border-radius: 1.5px;
    background-color: #d0021b;
    right: -4px;
    top: -4px;
    line-height: 12px;
    font-size: 9px; }

.complex .btn,
.adventure .btn {
  display: inline-block;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  border-radius: 2px;
  padding: 6px 10px;
  line-height: 1;
  font-style: normal;
  white-space: nowrap;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .complex .btn span,
  .adventure .btn span {
    margin-left: 8px; }
    .complex .btn span:first-child,
    .adventure .btn span:first-child {
      margin-left: 0; }
  .complex .btn .icon:before,
  .adventure .btn .icon:before {
    vertical-align: middle; }
  .complex .btn:hover,
  .adventure .btn:hover {
    border-color: #fff;
    color: #fff; }
  .complex .btn:disabled,
  .adventure .btn:disabled {
    opacity: 0.5;
    cursor: default; }
  .complex .btn.btn--green,
  .adventure .btn.btn--green {
    color: #7ed321;
    border-color: #7ed321; }
    .complex .btn.btn--green:hover,
    .adventure .btn.btn--green:hover {
      background-color: #8bc34a;
      border: solid 1px #7ed321;
      color: #3c763d; }
    .complex .btn.btn--green:disabled,
    .adventure .btn.btn--green:disabled {
      border-color: #fff;
      color: #fff; }
      .complex .btn.btn--green:disabled:hover,
      .adventure .btn.btn--green:disabled:hover {
        background-color: transparent; }
  .complex .btn.btn--transparent,
  .adventure .btn.btn--transparent {
    border-color: transparent; }
  .complex .btn.btn--lg,
  .adventure .btn.btn--lg {
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 15px 30px;
    min-width: 184px; }
    .complex .btn.btn--lg:disabled,
    .adventure .btn.btn--lg:disabled {
      border: solid 1px #b8b8b8 !important;
      color: #b8b8b8 !important;
      background-color: transparent !important; }
  .complex .btn.btn--sm,
  .adventure .btn.btn--sm {
    padding: 2px 4px; }

.complex .link,
.adventure .link {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #fff; }
  .complex .link .icon,
  .adventure .link .icon {
    font-size: 75%;
    margin-left: 5px; }
  .complex .link:hover,
  .adventure .link:hover {
    border-bottom: 1px solid #fff; }
  .complex .link.link--green,
  .adventure .link.link--green {
    color: #7ed321; }
    .complex .link.link--green:hover,
    .adventure .link.link--green:hover {
      border-color: #7ed321; }
  .complex .link.link--gray,
  .adventure .link.link--gray {
    color: #b8b8b8; }
    .complex .link.link--gray:hover,
    .adventure .link.link--gray:hover {
      border-color: #b8b8b8; }

.complex div.form,
.complex form.form,
.adventure div.form,
.adventure form.form {
  font-size: 16px; }
  .complex div.form .btn:hover,
  .complex form.form .btn:hover,
  .adventure div.form .btn:hover,
  .adventure form.form .btn:hover {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8); }
  .complex div.form h3.title,
  .complex form.form h3.title,
  .adventure div.form h3.title,
  .adventure form.form h3.title {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    margin: 30px 0;
    text-align: center;
    text-transform: uppercase; }
  .complex div.form h4.title,
  .complex form.form h4.title,
  .adventure div.form h4.title,
  .adventure form.form h4.title {
    color: #3d90c2;
    font-size: 18px;
    font-weight: normal;
    text-align: center; }
  .complex div.form .form-group,
  .complex form.form .form-group,
  .adventure div.form .form-group,
  .adventure form.form .form-group {
    font-size: 14px;
    margin-bottom: 15px; }
    .complex div.form .form-group label,
    .complex form.form .form-group label,
    .adventure div.form .form-group label,
    .adventure form.form .form-group label {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #6a6a6a;
      margin-bottom: 5px; }
    .complex div.form .form-group input,
    .complex form.form .form-group input,
    .adventure div.form .form-group input,
    .adventure form.form .form-group input {
      display: block;
      width: 100%;
      border: 1px solid #6a6a67;
      border-radius: 3px;
      background-color: transparent;
      line-height: 37px;
      color: #fff;
      font-size: 16px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 50px;
      max-width: 100%; }
    .complex div.form .form-group input[type="checkbox"],
    .complex form.form .form-group input[type="checkbox"],
    .adventure div.form .form-group input[type="checkbox"],
    .adventure form.form .form-group input[type="checkbox"] {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5em;
      vertical-align: middle; }
      .complex div.form .form-group input[type="checkbox"] + label,
      .complex form.form .form-group input[type="checkbox"] + label,
      .adventure div.form .form-group input[type="checkbox"] + label,
      .adventure form.form .form-group input[type="checkbox"] + label {
        display: inline-block; }
        .complex div.form .form-group input[type="checkbox"] + label a,
        .complex form.form .form-group input[type="checkbox"] + label a,
        .adventure div.form .form-group input[type="checkbox"] + label a,
        .adventure form.form .form-group input[type="checkbox"] + label a {
          color: #3d90c2 !important; }
    .complex div.form .form-group span,
    .complex div.form .form-group small,
    .complex form.form .form-group span,
    .complex form.form .form-group small,
    .adventure div.form .form-group span,
    .adventure div.form .form-group small,
    .adventure form.form .form-group span,
    .adventure form.form .form-group small {
      display: block;
      margin-top: 5px; }
  .complex div.form .btn,
  .complex form.form .btn,
  .adventure div.form .btn,
  .adventure form.form .btn {
    width: 100%;
    padding: 10px 20px;
    line-height: 30px;
    border-radius: 3px;
    text-transform: uppercase;
    background-image: linear-gradient(98deg, #8ec5e6, #3d90c2);
    color: #fff;
    border: 0;
    text-align: center;
    font-size: 16px;
    height: 50px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .complex div.form .btn.btn-secondary,
    .complex form.form .btn.btn-secondary,
    .adventure div.form .btn.btn-secondary,
    .adventure form.form .btn.btn-secondary {
      color: #8ec5e6;
      border: 1px solid #8ec5e6;
      background-color: transparent;
      background-image: none; }
  .complex div.form hr,
  .complex form.form hr,
  .adventure div.form hr,
  .adventure form.form hr {
    background: none;
    border: 0;
    border-bottom: 1px dashed #6a6a6a;
    margin-bottom: 8px; }
    .complex div.form hr.distance,
    .complex form.form hr.distance,
    .adventure div.form hr.distance,
    .adventure form.form hr.distance {
      margin-top: 30px; }
  .complex div.form .link,
  .complex form.form .link,
  .adventure div.form .link,
  .adventure form.form .link {
    font-size: 16px;
    color: #6a6a6a;
    text-decoration: none; }
    .complex div.form .link:hover,
    .complex form.form .link:hover,
    .adventure div.form .link:hover,
    .adventure form.form .link:hover {
      border-bottom: 0;
      text-decoration: underline; }
    .complex div.form .link.link--primary,
    .complex form.form .link.link--primary,
    .adventure div.form .link.link--primary,
    .adventure form.form .link.link--primary {
      color: #3d90c2; }
    .complex div.form .link.hoverable:hover,
    .complex form.form .link.hoverable:hover,
    .adventure div.form .link.hoverable:hover,
    .adventure form.form .link.hoverable:hover {
      color: #3d90c2;
      text-decoration: underline; }
  .complex div.form .error,
  .complex form.form .error,
  .adventure div.form .error,
  .adventure form.form .error {
    color: #d0021b !important; }
    .complex div.form .error a,
    .complex form.form .error a,
    .adventure div.form .error a,
    .adventure form.form .error a {
      color: #d0021b !important; }
    .complex div.form .error input,
    .complex div.form .error select,
    .complex div.form .error textarea,
    .complex form.form .error input,
    .complex form.form .error select,
    .complex form.form .error textarea,
    .adventure div.form .error input,
    .adventure div.form .error select,
    .adventure div.form .error textarea,
    .adventure form.form .error input,
    .adventure form.form .error select,
    .adventure form.form .error textarea {
      border-color: #d0021b; }
  .complex div.form .description,
  .complex form.form .description,
  .adventure div.form .description,
  .adventure form.form .description {
    margin: 30px 0;
    color: #6a6a6a; }
    .complex div.form .description ul,
    .complex div.form .description ol,
    .complex form.form .description ul,
    .complex form.form .description ol,
    .adventure div.form .description ul,
    .adventure div.form .description ol,
    .adventure form.form .description ul,
    .adventure form.form .description ol {
      padding-left: 0;
      font-size: 14px; }
      .complex div.form .description ul li,
      .complex div.form .description ol li,
      .complex form.form .description ul li,
      .complex form.form .description ol li,
      .adventure div.form .description ul li,
      .adventure div.form .description ol li,
      .adventure form.form .description ul li,
      .adventure form.form .description ol li {
        list-style-position: inside;
        margin: 5px; }
  .complex div.form footer,
  .complex form.form footer,
  .adventure div.form footer,
  .adventure form.form footer {
    font-size: 12px;
    color: #6a6a6a; }
    .complex div.form footer p,
    .complex form.form footer p,
    .adventure div.form footer p,
    .adventure form.form footer p {
      margin-bottom: 1px;
      margin-top: 2px; }
    .complex div.form footer a,
    .complex form.form footer a,
    .adventure div.form footer a,
    .adventure form.form footer a {
      color: #3d90c2; }

.complex .progress,
.adventure .progress {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  height: 10px;
  border-radius: 1.5px;
  border: solid 1px #fff; }
  .complex .progress .progress_handler,
  .adventure .progress .progress_handler {
    position: absolute;
    display: block;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 100%; }
  .complex .progress.progress--green,
  .adventure .progress.progress--green {
    border-color: #7ed321; }
    .complex .progress.progress--green .progress_handler,
    .adventure .progress.progress--green .progress_handler {
      background-color: #7ed321; }
  .complex .progress.progress--red,
  .adventure .progress.progress--red {
    border-color: #d0021b; }
    .complex .progress.progress--red .progress_handler,
    .adventure .progress.progress--red .progress_handler {
      background-color: #d0021b; }

.complex .tooltip__closer,
.adventure .tooltip__closer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999; }

.complex .ng-tooltip,
.adventure .ng-tooltip {
  position: fixed;
  max-width: 450px;
  z-index: 10000;
  opacity: 0;
  border: 1px solid #7ed321;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #191e22;
  padding: 10px 15px;
  color: #fff;
  font-size: 10px; }
  .complex .ng-tooltip h3,
  .adventure .ng-tooltip h3 {
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0 0 5px 0; }
  .complex .ng-tooltip hr,
  .adventure .ng-tooltip hr {
    height: 0px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #6d757b; }
  .complex .ng-tooltip .line,
  .adventure .ng-tooltip .line {
    width: 725px;
    padding: 3px 0; }
    .complex .ng-tooltip .line .key,
    .adventure .ng-tooltip .line .key {
      display: inline-block;
      width: 75px;
      color: #f5b300;
      float: left; }
      .complex .ng-tooltip .line .key:after,
      .adventure .ng-tooltip .line .key:after {
        content: ":"; }
    .complex .ng-tooltip .line .value,
    .adventure .ng-tooltip .line .value {
      display: inline-block;
      width: 350px; }
      .complex .ng-tooltip .line .value .info,
      .adventure .ng-tooltip .line .value .info {
        display: inline-block;
        float: right;
        width: 80px; }
        .complex .ng-tooltip .line .value .info.info--green,
        .adventure .ng-tooltip .line .value .info.info--green {
          color: #7ed321; }
        .complex .ng-tooltip .line .value .info.info--yellow,
        .adventure .ng-tooltip .line .value .info.info--yellow {
          color: #f8e71c; }

.complex .ng-tooltip-show,
.adventure .ng-tooltip-show {
  opacity: 0.95; }

.complex .ml-1,
.adventure .ml-1 {
  margin-left: 10px !important; }

.complex .ml-2,
.adventure .ml-2 {
  margin-left: 20px !important; }

.complex .mt-2,
.adventure .mt-2 {
  margin-top: 20px !important; }

.complex .mb-1,
.adventure .mb-1 {
  margin-bottom: 10px !important; }

.complex .mb-2,
.adventure .mb-2 {
  margin-bottom: 20px !important; }

.complex .mb-3,
.adventure .mb-3 {
  margin-bottom: 30px !important; }

.complex .mb-big,
.adventure .mb-big {
  margin-bottom: 200px !important; }
  @media (max-width: 768px) {
    .complex .mb-big,
    .adventure .mb-big {
      margin-bottom: 80px !important; } }

.complex .text-center,
.adventure .text-center {
  text-align: center; }

.complex .color-error,
.adventure .color-error {
  color: #d0021b; }

.complex .nowrap,
.adventure .nowrap {
  white-space: nowrap; }

.complex .w150,
.adventure .w150 {
  width: 150px; }

.complex .brand-logo,
.adventure .brand-logo {
  display: inline-block;
  width: 38px;
  height: 38px; }
  .complex .brand-logo span,
  .adventure .brand-logo span {
    display: block;
    background-color: #49afd9;
    border-radius: 3px;
    width: 14px;
    height: 14px;
    margin: 2.5px;
    float: left; }
    .complex .brand-logo span:first-child,
    .adventure .brand-logo span:first-child {
      background-color: #37deeb; }

.complex .brand-icon,
.adventure .brand-icon {
  margin-right: 1rem; }

.complex .brand-title,
.adventure .brand-title {
  display: inline-block;
  font-size: 36px;
  letter-spacing: 2px;
  color: #3d90c2;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 38px;
  margin: 0;
  vertical-align: top;
  position: relative;
  top: -1px; }

.complex .brand-title-description,
.adventure .brand-title-description {
  font-size: 20px;
  color: #6a6a6a;
  margin: 5px 0; }

.complex .dropdown-fog,
.adventure .dropdown-fog {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 199; }

.complex .dropdown-container,
.adventure .dropdown-container {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .complex .dropdown-container :first-child,
  .adventure .dropdown-container :first-child {
    cursor: pointer; }
  .complex .dropdown-container.dropdown--active .dropdown,
  .adventure .dropdown-container.dropdown--active .dropdown {
    display: block !important; }
  .complex .dropdown-container .dropdown-button,
  .complex .dropdown-container .dropdown-opener,
  .adventure .dropdown-container .dropdown-button,
  .adventure .dropdown-container .dropdown-opener {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .complex .dropdown-container .dropdown-button:hover,
    .complex .dropdown-container .dropdown-opener:hover,
    .adventure .dropdown-container .dropdown-button:hover,
    .adventure .dropdown-container .dropdown-opener:hover {
      color: #3d90c2; }
  .complex .dropdown-container .dropdown,
  .adventure .dropdown-container .dropdown {
    position: absolute;
    left: 0;
    top: 30px;
    border: 1px solid #3d90c2;
    background-color: #1f282f;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    display: none;
    z-index: 201;
    min-width: 200px;
    border-top: 0; }
    .complex .dropdown-container .dropdown.dropdown--right,
    .adventure .dropdown-container .dropdown.dropdown--right {
      left: auto;
      right: 0;
      margin-right: -26px; }
    .complex .dropdown-container .dropdown .dropdown-header,
    .adventure .dropdown-container .dropdown .dropdown-header {
      display: block;
      font-style: italic;
      color: #3d90c2;
      margin: 15px 20px 0 20px; }
    .complex .dropdown-container .dropdown .dropdown-item,
    .adventure .dropdown-container .dropdown .dropdown-item {
      margin: 3px 20px; }
    .complex .dropdown-container .dropdown .dropdown-separator,
    .adventure .dropdown-container .dropdown .dropdown-separator {
      display: block;
      border-bottom: 1px solid #353e46;
      margin: 15px 10px; }
    .complex .dropdown-container .dropdown .dropdown-button,
    .adventure .dropdown-container .dropdown .dropdown-button {
      display: block;
      background-color: #2b383e;
      text-align: center;
      font-style: italic;
      text-decoration: none !important;
      padding: 8px 16px;
      margin-top: 15px; }
    .complex .dropdown-container .dropdown a,
    .adventure .dropdown-container .dropdown a {
      color: inherit;
      text-decoration: none;
      cursor: pointer; }
      .complex .dropdown-container .dropdown a:hover,
      .adventure .dropdown-container .dropdown a:hover {
        text-decoration: underline; }

.complex #toast-container > div,
.adventure #toast-container > div {
  border-radius: 5px;
  opacity: 1;
  box-shadow: none !important; }

.complex .toast-close-button,
.adventure .toast-close-button {
  -webkit-text-shadow: none;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 0.15em;
  top: 0; }
  .complex .toast-close-button:hover,
  .adventure .toast-close-button:hover {
    opacity: 0.8; }

.complex .toast-title,
.adventure .toast-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px; }

.complex .toast-message,
.adventure .toast-message {
  height: 14px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic; }

.pojdmedozoo html,
.pojdmedozoo body,
.pojdmedozoo #root {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: auto; }

.pojdmedozoo body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #191e22;
  color: #fff;
  font-size: 15px; }

.pojdmedozoo #root {
  display: block;
  align-items: center;
  justify-content: center; }
  @media (min-height: 768px) {
    .pojdmedozoo #root {
      display: flex; } }

.pojdmedozoo * {
  box-sizing: border-box; }

.pojdmedozoo .container {
  position: relative;
  box-sizing: border-box;
  width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 25px; }
  .pojdmedozoo .container.container-small {
    width: 335px; }
    .pojdmedozoo .container.container-small > div {
      width: 100%; }
  .pojdmedozoo .container.container-medium {
    width: 720px; }
    .pojdmedozoo .container.container-medium > div {
      width: 100%; }
  .pojdmedozoo .container.container-middle {
    padding: 60px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    box-sizing: border-box;
    width: 568px;
    max-width: 100%;
    background-color: #191209; }
    .pojdmedozoo .container.container-middle > div {
      width: 335px; }

.pojdmedozoo .display-1 {
  display: block;
  text-align: center;
  font-size: 64px;
  line-height: 75px;
  font-weight: 500;
  color: #3d90c2; }
  .pojdmedozoo .display-1 small {
    color: #c0e2f7; }

.pojdmedozoo .display-2 {
  font-size: 24px;
  line-height: 24px; }

.pojdmedozoo .text-right {
  text-align: right; }

.pojdmedozoo .table {
  width: 100%;
  border-spacing: 0; }
  .pojdmedozoo .table th,
  .pojdmedozoo .table td {
    text-align: left;
    border-bottom: solid 0.5px #6d757b; }
    .pojdmedozoo .table th.borderless,
    .pojdmedozoo .table td.borderless {
      border-bottom: 0; }
    .pojdmedozoo .table th.wmin,
    .pojdmedozoo .table td.wmin {
      width: 1px; }
    .pojdmedozoo .table th.right,
    .pojdmedozoo .table td.right {
      text-align: right; }
    .pojdmedozoo .table th.nowrap,
    .pojdmedozoo .table td.nowrap {
      white-space: nowrap; }
    .pojdmedozoo .table th.nostyle,
    .pojdmedozoo .table td.nostyle {
      font-weight: normal;
      color: inherit;
      text-transform: none;
      font-style: normal; }
  .pojdmedozoo .table th {
    font-size: 11px;
    text-transform: uppercase;
    color: #f5b300;
    font-style: normal;
    padding: 2px 8px; }
  .pojdmedozoo .table td {
    font-size: 12px;
    padding: 8px;
    font-style: italic; }
  .pojdmedozoo .table tr > th:first-child,
  .pojdmedozoo .table tr > td:first-child {
    padding-left: 0; }
  .pojdmedozoo .table tr > th:last-child,
  .pojdmedozoo .table tr > td:last-child {
    padding-right: 0; }
  .pojdmedozoo .table tr:hover td {
    background-color: #1f282f; }
  .pojdmedozoo .table .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    max-width: 100%; }
    .pojdmedozoo .table .btn .icon {
      font-size: 10px; }

.pojdmedozoo app-modal {
  display: none; }

.pojdmedozoo .modal_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5); }
  .pojdmedozoo .modal_container .modal {
    width: 1024px;
    min-height: 400px;
    margin: 20vh auto;
    border: solid 1px #3d90c2;
    background-color: #000; }
    .pojdmedozoo .modal_container .modal .modal_header {
      font-size: 30px;
      line-height: 35px;
      font-weight: bold;
      font-style: italic;
      color: #fff;
      padding: 13px 26px; }
    .pojdmedozoo .modal_container .modal .modal_content {
      padding: 13px 26px; }
      .pojdmedozoo .modal_container .modal .modal_content:after {
        display: block;
        content: "";
        clear: both; }
    .pojdmedozoo .modal_container .modal.modal--small {
      width: 500px; }
    .pojdmedozoo .modal_container .modal .modal_close {
      cursor: pointer;
      display: inline-block;
      float: right;
      font-style: normal;
      padding: 0.5rem 1rem;
      margin-top: -0.5rem;
      margin-right: -1rem; }
      .pojdmedozoo .modal_container .modal .modal_close:hover {
        background-color: rgba(0, 0, 0, 0.1); }

.pojdmedozoo body.modal-open {
  overflow: hidden; }

.pojdmedozoo .sidebar {
  position: fixed;
  top: 60px;
  bottom: 60px;
  border: 1px solid #3d90c2;
  background-color: #1f282f;
  width: calc(100% - 200px);
  transition: all 0.2s ease-in-out;
  z-index: 101; }
  .pojdmedozoo .sidebar .sidebar_handler {
    position: absolute;
    left: 0;
    margin-top: 100px;
    cursor: pointer; }
    .pojdmedozoo .sidebar .sidebar_handler > div {
      padding: 25px 0; }
    .pojdmedozoo .sidebar .sidebar_handler span {
      display: inline-block;
      word-wrap: break-word;
      box-sizing: border-box;
      padding: 10px;
      width: 25px;
      background-color: #1f282f; }
    .pojdmedozoo .sidebar .sidebar_handler:hover span {
      color: #3d90c2; }
  .pojdmedozoo .sidebar .sidebar_content {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    overflow-y: auto; }
  .pojdmedozoo .sidebar.sidebar--right {
    border-right-width: 0;
    left: 100%;
    min-width: 5px;
    margin-left: -5px; }
    .pojdmedozoo .sidebar.sidebar--right .sidebar_handler {
      margin-left: -25px; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:before, .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:first-child:before, .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        top: 0;
        border-width: 0 0 25px 25px; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:first-child:before {
        border-bottom-color: #3d90c2;
        margin-top: -1px; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        border-bottom-color: #1f282f; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:last-child:before, .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        bottom: 0;
        border-width: 0 25px 25px 0; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:last-child:before {
        border-right-color: #3d90c2; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        border-right-color: #1f282f;
        left: 1px; }
      .pojdmedozoo .sidebar.sidebar--right .sidebar_handler span {
        border-left: 1px solid #3d90c2;
        margin-top: -1px; }
  .pojdmedozoo .sidebar.sidebar--active {
    left: 200px;
    margin-left: 0; }

.pojdmedozoo .sidebar-back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; }

.pojdmedozoo body.sidebar-open {
  overflow: hidden; }

.pojdmedozoo .panel {
  box-sizing: border-box;
  padding: 10px 25px; }
  .pojdmedozoo .panel.panel--collection {
    border-radius: 3px;
    box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
    margin-top: 5px; }
    .pojdmedozoo .panel.panel--collection > header {
      color: #3d90c2;
      line-height: 20px;
      margin-bottom: 8px; }
  .pojdmedozoo .panel.panel--uploader {
    color: #fff;
    border-radius: 3px;
    padding: 15px 20px; }
    .pojdmedozoo .panel.panel--uploader .panel_container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: dashed 2px #14577e;
      font-size: 16px;
      line-height: 19px; }
      .pojdmedozoo .panel.panel--uploader .panel_container > .panel_button {
        width: 100%;
        text-align: center;
        cursor: pointer; }
        .pojdmedozoo .panel.panel--uploader .panel_container > .panel_button input {
          position: absolute;
          width: 1px;
          height: 1px;
          opacity: 0; }
        .pojdmedozoo .panel.panel--uploader .panel_container > .panel_button .display-2 {
          line-height: 168px; }
      .pojdmedozoo .panel.panel--uploader .panel_container:hover {
        background-color: #000; }

.pojdmedozoo .cards:after {
  content: "";
  display: block;
  clear: both; }

.pojdmedozoo .cards .card {
  display: block;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  padding: 25px;
  width: calc(50% - 12.5px);
  float: left;
  margin-bottom: 30px;
  cursor: pointer; }
  .pojdmedozoo .cards .card:nth-child(2n) {
    margin-left: 25px; }
  .pojdmedozoo .cards .card .card_title {
    color: #3d90c2;
    margin: 0;
    font-size: 20px;
    line-height: 24px; }
  .pojdmedozoo .cards .card:hover {
    box-shadow: none;
    outline: 1px solid #3d90c2; }

.pojdmedozoo .collection {
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #3d90c2;
  background-color: #000;
  margin-top: 10px; }
  .pojdmedozoo .collection > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: #3d90c2;
    height: 40px;
    padding: 8px 8px;
    line-height: 24px; }
    .pojdmedozoo .collection > header:hover {
      background-color: #1f282f; }
  .pojdmedozoo .collection .collection_info {
    font-size: 15px;
    color: #979797;
    margin: 0 10px; }
  .pojdmedozoo .collection .collection_content {
    display: none;
    padding: 8px 0;
    margin: 0 8px; }
  .pojdmedozoo .collection .collection_actions {
    display: inline-block;
    width: 284px;
    text-align: right; }
  .pojdmedozoo .collection .collection_toggle {
    vertical-align: middle;
    margin: 0 4px 0 12px;
    cursor: pointer; }
    .pojdmedozoo .collection .collection_toggle.collection_toggle--opened {
      display: none; }
    .pojdmedozoo .collection .collection_toggle.collection_toggle--closed {
      display: inline-block; }
  .pojdmedozoo .collection.collection--active > header {
    border-bottom: .5px solid #979797; }
  .pojdmedozoo .collection.collection--active .collection_content {
    display: block; }
  .pojdmedozoo .collection.collection--active .collection_toggle.collection_toggle--opened {
    display: inline-block; }
  .pojdmedozoo .collection.collection--active .collection_toggle.collection_toggle--closed {
    display: none; }
  .pojdmedozoo .collection:first-child {
    margin-top: 0; }

.pojdmedozoo .badge {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 59px;
  height: 16px;
  border-radius: 1.5px;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  margin: 0 4px;
  font-style: normal;
  transition: all 0.2s ease-in-out; }
  .pojdmedozoo .badge.badge--gray {
    background-color: #4a4a4a;
    color: #fff;
    border: 0; }
  .pojdmedozoo .badge.badge--orange {
    background-color: #e29d28;
    color: #fff;
    border: 0; }
  .pojdmedozoo .badge.badge--blue {
    background-color: #3d90c2;
    color: #fff;
    border: 0; }
  .pojdmedozoo .badge.badge--green {
    background-color: #8bc34a;
    color: #fff;
    border: 0; }
  .pojdmedozoo .badge.badge--red {
    background-color: #800303;
    color: #fff;
    border: 0; }
  .pojdmedozoo .badge[data-index]:after {
    content: attr(data-index);
    display: inline-block;
    position: absolute;
    min-width: 12px;
    height: 12px;
    border-radius: 1.5px;
    background-color: #d0021b;
    right: -4px;
    top: -4px;
    line-height: 12px;
    font-size: 9px; }

.pojdmedozoo .btn {
  display: inline-block;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  border-radius: 2px;
  padding: 6px 10px;
  line-height: 1;
  font-style: normal;
  white-space: nowrap;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pojdmedozoo .btn span {
    margin-left: 8px; }
    .pojdmedozoo .btn span:first-child {
      margin-left: 0; }
  .pojdmedozoo .btn .icon:before {
    vertical-align: middle; }
  .pojdmedozoo .btn:hover {
    border-color: #fff;
    color: #fff; }
  .pojdmedozoo .btn:disabled {
    opacity: 0.5;
    cursor: default; }
  .pojdmedozoo .btn.btn--green {
    color: #7ed321;
    border-color: #7ed321; }
    .pojdmedozoo .btn.btn--green:hover {
      background-color: #8bc34a;
      border: solid 1px #7ed321;
      color: #3c763d; }
    .pojdmedozoo .btn.btn--green:disabled {
      border-color: #fff;
      color: #fff; }
      .pojdmedozoo .btn.btn--green:disabled:hover {
        background-color: transparent; }
  .pojdmedozoo .btn.btn--transparent {
    border-color: transparent; }
  .pojdmedozoo .btn.btn--lg {
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 15px 30px;
    min-width: 184px; }
    .pojdmedozoo .btn.btn--lg:disabled {
      border: solid 1px #b8b8b8 !important;
      color: #b8b8b8 !important;
      background-color: transparent !important; }
  .pojdmedozoo .btn.btn--sm {
    padding: 2px 4px; }

.pojdmedozoo .link {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #fff; }
  .pojdmedozoo .link .icon {
    font-size: 75%;
    margin-left: 5px; }
  .pojdmedozoo .link:hover {
    border-bottom: 1px solid #fff; }
  .pojdmedozoo .link.link--green {
    color: #7ed321; }
    .pojdmedozoo .link.link--green:hover {
      border-color: #7ed321; }
  .pojdmedozoo .link.link--gray {
    color: #b8b8b8; }
    .pojdmedozoo .link.link--gray:hover {
      border-color: #b8b8b8; }

.pojdmedozoo div.form,
.pojdmedozoo form.form {
  font-size: 16px; }
  .pojdmedozoo div.form .btn:hover,
  .pojdmedozoo form.form .btn:hover {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8); }
  .pojdmedozoo div.form h3.title,
  .pojdmedozoo form.form h3.title {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    margin: 30px 0;
    text-align: center;
    text-transform: uppercase; }
  .pojdmedozoo div.form h4.title,
  .pojdmedozoo form.form h4.title {
    color: #5fb500;
    font-size: 18px;
    font-weight: normal;
    text-align: center; }
  .pojdmedozoo div.form .form-group,
  .pojdmedozoo form.form .form-group {
    font-size: 14px;
    margin-bottom: 15px; }
    .pojdmedozoo div.form .form-group label,
    .pojdmedozoo form.form .form-group label {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #5fb500;
      margin-bottom: 5px; }
    .pojdmedozoo div.form .form-group input,
    .pojdmedozoo form.form .form-group input {
      display: block;
      width: 100%;
      border: 1px solid #6a6a67;
      border-radius: 3px;
      background-color: transparent;
      line-height: 37px;
      color: #fff;
      font-size: 16px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 50px;
      max-width: 100%; }
    .pojdmedozoo div.form .form-group input[type="checkbox"],
    .pojdmedozoo form.form .form-group input[type="checkbox"] {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5em;
      vertical-align: middle; }
      .pojdmedozoo div.form .form-group input[type="checkbox"] + label,
      .pojdmedozoo form.form .form-group input[type="checkbox"] + label {
        display: inline-block; }
        .pojdmedozoo div.form .form-group input[type="checkbox"] + label a,
        .pojdmedozoo form.form .form-group input[type="checkbox"] + label a {
          color: rgba(255, 146, 0, 0.8) !important; }
          .pojdmedozoo div.form .form-group input[type="checkbox"] + label a:hover,
          .pojdmedozoo form.form .form-group input[type="checkbox"] + label a:hover {
            color: #ff9200 !important; }
    .pojdmedozoo div.form .form-group span,
    .pojdmedozoo div.form .form-group small,
    .pojdmedozoo form.form .form-group span,
    .pojdmedozoo form.form .form-group small {
      display: block;
      margin-top: 5px; }
  .pojdmedozoo div.form .btn,
  .pojdmedozoo form.form .btn {
    width: 100%;
    padding: 10px 20px;
    line-height: 30px;
    border-radius: 3px;
    border: 1px solid #FF9200;
    background-color: rgba(255, 146, 0, 0.15);
    text-transform: uppercase;
    color: #FF9200;
    text-align: center;
    font-size: 16px;
    height: 50px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pojdmedozoo div.form .btn.btn-secondary,
    .pojdmedozoo form.form .btn.btn-secondary {
      color: #FF9200;
      border: 1px solid #FF9200;
      background-image: none; }
  .pojdmedozoo div.form hr,
  .pojdmedozoo form.form hr {
    background: none;
    border: 0;
    border-bottom: 1px dashed #6a6a6a;
    margin-bottom: 8px; }
    .pojdmedozoo div.form hr.distance,
    .pojdmedozoo form.form hr.distance {
      margin-top: 30px; }
  .pojdmedozoo div.form .link,
  .pojdmedozoo form.form .link {
    font-size: 16px;
    color: #6a6a6a;
    text-decoration: none; }
    .pojdmedozoo div.form .link:hover,
    .pojdmedozoo form.form .link:hover {
      border-bottom: 0;
      text-decoration: underline;
      color: #ff9200 !important; }
    .pojdmedozoo div.form .link.link--primary,
    .pojdmedozoo form.form .link.link--primary {
      color: rgba(255, 146, 0, 0.8); }
    .pojdmedozoo div.form .link.hoverable:hover,
    .pojdmedozoo form.form .link.hoverable:hover {
      color: #FF9200;
      text-decoration: underline; }
  .pojdmedozoo div.form .error,
  .pojdmedozoo form.form .error {
    color: #d0021b !important; }
    .pojdmedozoo div.form .error a,
    .pojdmedozoo form.form .error a {
      color: #d0021b !important; }
    .pojdmedozoo div.form .error input,
    .pojdmedozoo div.form .error select,
    .pojdmedozoo div.form .error textarea,
    .pojdmedozoo form.form .error input,
    .pojdmedozoo form.form .error select,
    .pojdmedozoo form.form .error textarea {
      border-color: #d0021b; }
  .pojdmedozoo div.form .description,
  .pojdmedozoo form.form .description {
    margin: 30px 0;
    color: #6a6a6a; }
    .pojdmedozoo div.form .description ul,
    .pojdmedozoo div.form .description ol,
    .pojdmedozoo form.form .description ul,
    .pojdmedozoo form.form .description ol {
      padding-left: 0;
      font-size: 14px; }
      .pojdmedozoo div.form .description ul li,
      .pojdmedozoo div.form .description ol li,
      .pojdmedozoo form.form .description ul li,
      .pojdmedozoo form.form .description ol li {
        list-style-position: inside;
        margin: 5px; }
  .pojdmedozoo div.form footer,
  .pojdmedozoo form.form footer {
    font-size: 12px;
    color: #6a6a6a; }
    .pojdmedozoo div.form footer p,
    .pojdmedozoo form.form footer p {
      margin-bottom: 1px;
      margin-top: 2px; }
    .pojdmedozoo div.form footer a,
    .pojdmedozoo form.form footer a {
      color: rgba(255, 146, 0, 0.8); }
      .pojdmedozoo div.form footer a:hover,
      .pojdmedozoo form.form footer a:hover {
        color: #FF9200; }

.pojdmedozoo .progress {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  height: 10px;
  border-radius: 1.5px;
  border: solid 1px #fff; }
  .pojdmedozoo .progress .progress_handler {
    position: absolute;
    display: block;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 100%; }
  .pojdmedozoo .progress.progress--green {
    border-color: #7ed321; }
    .pojdmedozoo .progress.progress--green .progress_handler {
      background-color: #7ed321; }
  .pojdmedozoo .progress.progress--red {
    border-color: #d0021b; }
    .pojdmedozoo .progress.progress--red .progress_handler {
      background-color: #d0021b; }

.pojdmedozoo .tooltip__closer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999; }

.pojdmedozoo .ng-tooltip {
  position: fixed;
  max-width: 450px;
  z-index: 10000;
  opacity: 0;
  border: 1px solid #7ed321;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #191e22;
  padding: 10px 15px;
  color: #fff;
  font-size: 10px; }
  .pojdmedozoo .ng-tooltip h3 {
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0 0 5px 0; }
  .pojdmedozoo .ng-tooltip hr {
    height: 0px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #6d757b; }
  .pojdmedozoo .ng-tooltip .line {
    width: 725px;
    padding: 3px 0; }
    .pojdmedozoo .ng-tooltip .line .key {
      display: inline-block;
      width: 75px;
      color: #f5b300;
      float: left; }
      .pojdmedozoo .ng-tooltip .line .key:after {
        content: ":"; }
    .pojdmedozoo .ng-tooltip .line .value {
      display: inline-block;
      width: 350px; }
      .pojdmedozoo .ng-tooltip .line .value .info {
        display: inline-block;
        float: right;
        width: 80px; }
        .pojdmedozoo .ng-tooltip .line .value .info.info--green {
          color: #7ed321; }
        .pojdmedozoo .ng-tooltip .line .value .info.info--yellow {
          color: #f8e71c; }

.pojdmedozoo .ng-tooltip-show {
  opacity: 0.95; }

.pojdmedozoo .ml-1 {
  margin-left: 10px !important; }

.pojdmedozoo .ml-2 {
  margin-left: 20px !important; }

.pojdmedozoo .mt-2 {
  margin-top: 20px !important; }

.pojdmedozoo .mb-1 {
  margin-bottom: 10px !important; }

.pojdmedozoo .mb-2 {
  margin-bottom: 20px !important; }

.pojdmedozoo .mb-3 {
  margin-bottom: 30px !important; }

.pojdmedozoo .mb-big {
  margin-bottom: 200px !important; }
  @media (max-width: 768px) {
    .pojdmedozoo .mb-big {
      margin-bottom: 80px !important; } }

.pojdmedozoo .text-center {
  text-align: center; }

.pojdmedozoo .color-error {
  color: #d0021b; }

.pojdmedozoo .nowrap {
  white-space: nowrap; }

.pojdmedozoo .w150 {
  width: 150px; }

.pojdmedozoo .brand-logo {
  display: inline-block;
  width: 38px;
  height: 38px; }
  .pojdmedozoo .brand-logo span {
    display: block;
    background-color: #49afd9;
    border-radius: 3px;
    width: 14px;
    height: 14px;
    margin: 2.5px;
    float: left; }
    .pojdmedozoo .brand-logo span:first-child {
      background-color: #37deeb; }

.pojdmedozoo .brand-icon {
  margin-right: 1rem;
  width: 12rem; }

.pojdmedozoo .brand-title {
  display: inline-block;
  font-size: 36px;
  letter-spacing: 2px;
  color: #5fb500;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 38px;
  margin: 0;
  vertical-align: top;
  position: relative;
  top: -1px; }

.pojdmedozoo .brand-title-description {
  font-size: 20px;
  color: #6a6a6a;
  margin: 5px 0; }

.pojdmedozoo .dropdown-fog {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 199; }

.pojdmedozoo .dropdown-container {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .pojdmedozoo .dropdown-container :first-child {
    cursor: pointer; }
  .pojdmedozoo .dropdown-container.dropdown--active .dropdown {
    display: block !important; }
  .pojdmedozoo .dropdown-container .dropdown-button,
  .pojdmedozoo .dropdown-container .dropdown-opener {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .pojdmedozoo .dropdown-container .dropdown-button:hover,
    .pojdmedozoo .dropdown-container .dropdown-opener:hover {
      color: #3d90c2; }
  .pojdmedozoo .dropdown-container .dropdown {
    position: absolute;
    left: 0;
    top: 30px;
    border: 1px solid #3d90c2;
    background-color: #1f282f;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    display: none;
    z-index: 201;
    min-width: 200px;
    border-top: 0; }
    .pojdmedozoo .dropdown-container .dropdown.dropdown--right {
      left: auto;
      right: 0;
      margin-right: -26px; }
    .pojdmedozoo .dropdown-container .dropdown .dropdown-header {
      display: block;
      font-style: italic;
      color: #3d90c2;
      margin: 15px 20px 0 20px; }
    .pojdmedozoo .dropdown-container .dropdown .dropdown-item {
      margin: 3px 20px; }
    .pojdmedozoo .dropdown-container .dropdown .dropdown-separator {
      display: block;
      border-bottom: 1px solid #353e46;
      margin: 15px 10px; }
    .pojdmedozoo .dropdown-container .dropdown .dropdown-button {
      display: block;
      background-color: #2b383e;
      text-align: center;
      font-style: italic;
      text-decoration: none !important;
      padding: 8px 16px;
      margin-top: 15px; }
    .pojdmedozoo .dropdown-container .dropdown a {
      color: inherit;
      text-decoration: none;
      cursor: pointer; }
      .pojdmedozoo .dropdown-container .dropdown a:hover {
        text-decoration: underline; }

.pojdmedozoo #toast-container > div {
  border-radius: 5px;
  opacity: 1;
  box-shadow: none !important; }

.pojdmedozoo .toast-close-button {
  -webkit-text-shadow: none;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 0.15em;
  top: 0; }
  .pojdmedozoo .toast-close-button:hover {
    opacity: 0.8; }

.pojdmedozoo .toast-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px; }

.pojdmedozoo .toast-message {
  height: 14px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic; }

.hhi html,
.hhi body,
.hhi #root {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: auto; }

.hhi #root {
  display: flex;
  align-items: center;
  justify-content: center; }

.hhi body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #191e22;
  color: #fff;
  font-size: 15px; }

.hhi * {
  box-sizing: border-box; }

.hhi .container {
  box-sizing: border-box;
  width: 1024px;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0; }
  .hhi .container.container-small {
    width: 335px; }
    .hhi .container.container-small > div {
      width: 100%; }
  .hhi .container.container-medium {
    width: 720px; }
    .hhi .container.container-medium > div {
      width: 100%; }
  .hhi .container.container-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    box-sizing: border-box;
    width: 768px;
    max-width: 100%;
    background-color: rgba(25, 30, 34, 0.9); }
    .hhi .container.container-middle > div {
      width: 335px;
      position: relative; }
  .hhi .container .contentWrapper {
    padding: 50px 0; }
  .hhi .container > div {
    overflow: unset;
    height: 100%;
    margin: auto; }
  @media (max-width: 768px) {
    .hhi .container {
      position: fixed;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;
      overflow: auto;
      margin: 0; }
      .hhi .container.container-middle {
        height: 100%; }
      .hhi .container > div {
        width: 335px;
        max-width: 100%;
        max-height: 100%;
        padding: 0 .5em; } }

.hhi .display-1 {
  display: block;
  text-align: center;
  font-size: 64px;
  line-height: 75px;
  font-weight: 500;
  color: #3d90c2; }
  .hhi .display-1 small {
    color: #c0e2f7; }

.hhi .display-2 {
  font-size: 24px;
  line-height: 24px; }

.hhi .text-right {
  text-align: right; }

.hhi .table {
  width: 100%;
  border-spacing: 0; }
  .hhi .table th,
  .hhi .table td {
    text-align: left;
    border-bottom: solid 0.5px #6d757b; }
    .hhi .table th.borderless,
    .hhi .table td.borderless {
      border-bottom: 0; }
    .hhi .table th.wmin,
    .hhi .table td.wmin {
      width: 1px; }
    .hhi .table th.right,
    .hhi .table td.right {
      text-align: right; }
    .hhi .table th.nowrap,
    .hhi .table td.nowrap {
      white-space: nowrap; }
    .hhi .table th.nostyle,
    .hhi .table td.nostyle {
      font-weight: normal;
      color: inherit;
      text-transform: none;
      font-style: normal; }
  .hhi .table th {
    font-size: 11px;
    text-transform: uppercase;
    color: #f5b300;
    font-style: normal;
    padding: 2px 8px; }
  .hhi .table td {
    font-size: 12px;
    padding: 8px;
    font-style: italic; }
  .hhi .table tr > th:first-child,
  .hhi .table tr > td:first-child {
    padding-left: 0; }
  .hhi .table tr > th:last-child,
  .hhi .table tr > td:last-child {
    padding-right: 0; }
  .hhi .table tr:hover td {
    background-color: #1f282f; }
  .hhi .table .btn {
    font-size: 12px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .hhi .table .btn .icon {
      font-size: 10px; }

.hhi app-modal {
  display: none; }

.hhi .modal_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5); }
  .hhi .modal_container .modal {
    width: 1024px;
    min-height: 400px;
    margin: 20vh auto;
    border: solid 1px #3d90c2;
    background-color: #000; }
    .hhi .modal_container .modal .modal_header {
      font-size: 30px;
      line-height: 35px;
      font-weight: bold;
      font-style: italic;
      color: #fff;
      padding: 13px 26px; }
    .hhi .modal_container .modal .modal_content {
      padding: 13px 26px; }
      .hhi .modal_container .modal .modal_content:after {
        display: block;
        content: "";
        clear: both; }
    .hhi .modal_container .modal.modal--small {
      width: 500px; }
    .hhi .modal_container .modal .modal_close {
      cursor: pointer;
      display: inline-block;
      float: right;
      font-style: normal;
      padding: 0.5rem 1rem;
      margin-top: -0.5rem;
      margin-right: -1rem; }
      .hhi .modal_container .modal .modal_close:hover {
        background-color: rgba(0, 0, 0, 0.1); }

.hhi body.modal-open {
  overflow: hidden; }

.hhi .sidebar {
  position: fixed;
  top: 60px;
  bottom: 60px;
  border: 1px solid #3d90c2;
  background-color: #1f282f;
  width: calc(100% - 200px);
  transition: all 0.2s ease-in-out;
  z-index: 101; }
  .hhi .sidebar .sidebar_handler {
    position: absolute;
    left: 0;
    margin-top: 100px;
    cursor: pointer; }
    .hhi .sidebar .sidebar_handler > div {
      padding: 25px 0; }
    .hhi .sidebar .sidebar_handler span {
      display: inline-block;
      word-wrap: break-word;
      box-sizing: border-box;
      padding: 10px;
      width: 25px;
      background-color: #1f282f; }
    .hhi .sidebar .sidebar_handler:hover span {
      color: #3d90c2; }
  .hhi .sidebar .sidebar_content {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    overflow-y: auto; }
  .hhi .sidebar.sidebar--right {
    border-right-width: 0;
    left: 100%;
    min-width: 5px;
    margin-left: -5px; }
    .hhi .sidebar.sidebar--right .sidebar_handler {
      margin-left: -25px; }
      .hhi .sidebar.sidebar--right .sidebar_handler i:before, .hhi .sidebar.sidebar--right .sidebar_handler i:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent; }
      .hhi .sidebar.sidebar--right .sidebar_handler i:first-child:before, .hhi .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        top: 0;
        border-width: 0 0 25px 25px; }
      .hhi .sidebar.sidebar--right .sidebar_handler i:first-child:before {
        border-bottom-color: #3d90c2;
        margin-top: -1px; }
      .hhi .sidebar.sidebar--right .sidebar_handler i:first-child:after {
        border-bottom-color: #1f282f; }
      .hhi .sidebar.sidebar--right .sidebar_handler i:last-child:before, .hhi .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        bottom: 0;
        border-width: 0 25px 25px 0; }
      .hhi .sidebar.sidebar--right .sidebar_handler i:last-child:before {
        border-right-color: #3d90c2; }
      .hhi .sidebar.sidebar--right .sidebar_handler i:last-child:after {
        border-right-color: #1f282f;
        left: 1px; }
      .hhi .sidebar.sidebar--right .sidebar_handler span {
        border-left: 1px solid #3d90c2;
        margin-top: -1px; }
  .hhi .sidebar.sidebar--active {
    left: 200px;
    margin-left: 0; }

.hhi .sidebar-back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100; }

.hhi body.sidebar-open {
  overflow: hidden; }

.hhi .panel {
  box-sizing: border-box;
  padding: 10px 25px; }
  .hhi .panel.panel--collection {
    border-radius: 3px;
    box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
    margin-top: 5px; }
    .hhi .panel.panel--collection > header {
      color: #3d90c2;
      line-height: 20px;
      margin-bottom: 8px; }
  .hhi .panel.panel--uploader {
    color: #fff;
    border-radius: 3px;
    padding: 15px 20px; }
    .hhi .panel.panel--uploader .panel_container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: dashed 2px #14577e;
      font-size: 16px;
      line-height: 19px; }
      .hhi .panel.panel--uploader .panel_container > .panel_button {
        width: 100%;
        text-align: center;
        cursor: pointer; }
        .hhi .panel.panel--uploader .panel_container > .panel_button input {
          position: absolute;
          width: 1px;
          height: 1px;
          opacity: 0; }
        .hhi .panel.panel--uploader .panel_container > .panel_button .display-2 {
          line-height: 168px; }
      .hhi .panel.panel--uploader .panel_container:hover {
        background-color: #000; }

.hhi .cards:after {
  content: "";
  display: block;
  clear: both; }

.hhi .cards .card {
  display: block;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  padding: 25px;
  width: calc(50% - 12.5px);
  float: left;
  margin-bottom: 30px;
  cursor: pointer; }
  .hhi .cards .card:nth-child(2n) {
    margin-left: 25px; }
  .hhi .cards .card .card_title {
    color: #3d90c2;
    margin: 0;
    font-size: 20px;
    line-height: 24px; }
  .hhi .cards .card:hover {
    box-shadow: none;
    outline: 1px solid #3d90c2; }

.hhi .collection {
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #3d90c2;
  background-color: #000;
  margin-top: 10px; }
  .hhi .collection > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: #3d90c2;
    height: 40px;
    padding: 8px 8px;
    line-height: 24px; }
    .hhi .collection > header:hover {
      background-color: #1f282f; }
  .hhi .collection .collection_info {
    font-size: 15px;
    color: #979797;
    margin: 0 10px; }
  .hhi .collection .collection_content {
    display: none;
    padding: 8px 0;
    margin: 0 8px; }
  .hhi .collection .collection_actions {
    display: inline-block;
    width: 284px;
    text-align: right; }
  .hhi .collection .collection_toggle {
    vertical-align: middle;
    margin: 0 4px 0 12px;
    cursor: pointer; }
    .hhi .collection .collection_toggle.collection_toggle--opened {
      display: none; }
    .hhi .collection .collection_toggle.collection_toggle--closed {
      display: inline-block; }
  .hhi .collection.collection--active > header {
    border-bottom: .5px solid #979797; }
  .hhi .collection.collection--active .collection_content {
    display: block; }
  .hhi .collection.collection--active .collection_toggle.collection_toggle--opened {
    display: inline-block; }
  .hhi .collection.collection--active .collection_toggle.collection_toggle--closed {
    display: none; }
  .hhi .collection:first-child {
    margin-top: 0; }

.hhi .badge {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 59px;
  height: 16px;
  border-radius: 1.5px;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  margin: 0 4px;
  font-style: normal;
  transition: all 0.2s ease-in-out; }
  .hhi .badge.badge--gray {
    background-color: #4a4a4a;
    color: #fff;
    border: 0; }
  .hhi .badge.badge--orange {
    background-color: #e29d28;
    color: #fff;
    border: 0; }
  .hhi .badge.badge--blue {
    background-color: #3d90c2;
    color: #fff;
    border: 0; }
  .hhi .badge.badge--green {
    background-color: #8bc34a;
    color: #fff;
    border: 0; }
  .hhi .badge.badge--red {
    background-color: #800303;
    color: #fff;
    border: 0; }
  .hhi .badge[data-index]:after {
    content: attr(data-index);
    display: inline-block;
    position: absolute;
    min-width: 12px;
    height: 12px;
    border-radius: 1.5px;
    background-color: #d0021b;
    right: -4px;
    top: -4px;
    line-height: 12px;
    font-size: 9px; }

.hhi .btn {
  display: inline-block;
  border: solid 1px #b8b8b8;
  color: #b8b8b8;
  border-radius: 2px;
  padding: 6px 10px;
  line-height: 1;
  font-style: normal;
  white-space: nowrap;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .hhi .btn span {
    margin-left: 8px; }
    .hhi .btn span:first-child {
      margin-left: 0; }
  .hhi .btn .icon:before {
    vertical-align: middle; }
  .hhi .btn:hover {
    border-color: #fff;
    color: #fff; }
  .hhi .btn:disabled {
    opacity: 0.5;
    cursor: default; }
  .hhi .btn.btn--green {
    color: #7ed321;
    border-color: #7ed321; }
    .hhi .btn.btn--green:hover {
      background-color: #8bc34a;
      border: solid 1px #7ed321;
      color: #3c763d; }
    .hhi .btn.btn--green:disabled {
      border-color: #fff;
      color: #fff; }
      .hhi .btn.btn--green:disabled:hover {
        background-color: transparent; }
  .hhi .btn.btn--transparent {
    border-color: transparent; }
  .hhi .btn.btn--lg {
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 15px 30px;
    min-width: 184px; }
    .hhi .btn.btn--lg:disabled {
      border: solid 1px #b8b8b8 !important;
      color: #b8b8b8 !important;
      background-color: transparent !important; }
  .hhi .btn.btn--sm {
    padding: 2px 4px; }

.hhi .link {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #fff; }
  .hhi .link .icon {
    font-size: 75%;
    margin-left: 5px; }
  .hhi .link:hover {
    border-bottom: 1px solid #fff; }
  .hhi .link.link--green {
    color: #7ed321; }
    .hhi .link.link--green:hover {
      border-color: #7ed321; }
  .hhi .link.link--gray {
    color: #b8b8b8; }
    .hhi .link.link--gray:hover {
      border-color: #b8b8b8; }

.hhi div.form,
.hhi form.form {
  font-size: 16px; }
  .hhi div.form .btn:hover,
  .hhi form.form .btn:hover {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7); }
  .hhi div.form h3.title,
  .hhi form.form h3.title {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    margin: 30px 0;
    text-align: center;
    text-transform: uppercase; }
  .hhi div.form h4.title,
  .hhi form.form h4.title {
    color: #3d90c2;
    font-size: 18px;
    font-weight: normal;
    text-align: center; }
  .hhi div.form .form-group,
  .hhi form.form .form-group {
    font-size: 14px;
    margin-bottom: 15px; }
    .hhi div.form .form-group label,
    .hhi form.form .form-group label {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #6a6a6a;
      margin-bottom: 5px; }
    .hhi div.form .form-group input,
    .hhi form.form .form-group input {
      display: block;
      width: 100%;
      line-height: 37px;
      color: #fff;
      font-size: 16px;
      box-sizing: border-box;
      padding: 10px 20px;
      width: 335px;
      height: 61px;
      border-radius: 3px;
      border: solid 1px #6a6a67;
      background-color: #191e22;
      max-width: 100%; }
    .hhi div.form .form-group span,
    .hhi div.form .form-group small,
    .hhi form.form .form-group span,
    .hhi form.form .form-group small {
      display: block;
      margin-top: 5px; }
  .hhi div.form .rememberMe,
  .hhi form.form .rememberMe {
    color: #6a6a6a;
    font-size: 16px;
    letter-spacing: normal; }
  .hhi div.form .btn,
  .hhi form.form .btn {
    padding: 10px 20px;
    line-height: 30px;
    border-radius: 3px;
    text-transform: uppercase;
    text-align: center;
    line-height: 30px;
    border: 0;
    width: 335px;
    height: 61px;
    background-image: linear-gradient(to bottom, #fad961, #f76b1c);
    font-size: 16px;
    color: black;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .hhi div.form .btn.btn-secondary,
    .hhi form.form .btn.btn-secondary {
      background: transparent;
      border: solid 1px #f5b300;
      background-color: #191e22;
      color: #f5b300;
      line-height: 40px; }
  .hhi div.form hr,
  .hhi form.form hr {
    background: none;
    border: 0;
    border-bottom: 1px dashed #6a6a6a;
    margin-top: 65px;
    margin-bottom: 21px; }
    .hhi div.form hr.distance,
    .hhi form.form hr.distance {
      margin-top: 30px; }
  .hhi div.form .link,
  .hhi form.form .link {
    font-size: 16px;
    color: #6a6a6a;
    text-decoration: none; }
    .hhi div.form .link:hover,
    .hhi form.form .link:hover {
      border-bottom: 0;
      text-decoration: underline; }
    .hhi div.form .link.hoverable:hover,
    .hhi form.form .link.hoverable:hover {
      color: #3d90c2;
      text-decoration: underline; }
  .hhi div.form .error,
  .hhi form.form .error {
    color: #d0021b !important; }
    .hhi div.form .error a,
    .hhi form.form .error a {
      color: #d0021b !important; }
    .hhi div.form .error input,
    .hhi div.form .error select,
    .hhi div.form .error textarea,
    .hhi form.form .error input,
    .hhi form.form .error select,
    .hhi form.form .error textarea {
      border-color: #d0021b; }
  .hhi div.form .description,
  .hhi form.form .description {
    margin: 30px 0;
    color: #6a6a6a; }
    .hhi div.form .description ul,
    .hhi div.form .description ol,
    .hhi form.form .description ul,
    .hhi form.form .description ol {
      padding-left: 0;
      font-size: 14px; }
      .hhi div.form .description ul li,
      .hhi div.form .description ol li,
      .hhi form.form .description ul li,
      .hhi form.form .description ol li {
        list-style-position: inside;
        margin: 5px; }
  .hhi div.form footer,
  .hhi form.form footer {
    font-size: 12px;
    color: #6a6a6a; }
    .hhi div.form footer p,
    .hhi form.form footer p {
      margin-bottom: 1px;
      margin-top: 2px;
      text-decoration: none; }
    .hhi div.form footer p a,
    .hhi form.form footer p a {
      color: #f5b300;
      text-decoration: none; }
      .hhi div.form footer p a:hover,
      .hhi form.form footer p a:hover {
        text-decoration: underline; }
    .hhi div.form footer a,
    .hhi form.form footer a {
      color: #f5b300;
      text-decoration: none; }
      .hhi div.form footer a:hover,
      .hhi form.form footer a:hover {
        text-decoration: underline; }

.hhi .progress {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  height: 10px;
  border-radius: 1.5px;
  border: solid 1px #fff; }
  .hhi .progress .progress_handler {
    position: absolute;
    display: block;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 100%; }
  .hhi .progress.progress--green {
    border-color: #7ed321; }
    .hhi .progress.progress--green .progress_handler {
      background-color: #7ed321; }
  .hhi .progress.progress--red {
    border-color: #d0021b; }
    .hhi .progress.progress--red .progress_handler {
      background-color: #d0021b; }

.hhi .tooltip__closer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999; }

.hhi .ng-tooltip {
  position: fixed;
  max-width: 450px;
  z-index: 10000;
  opacity: 0;
  border: 1px solid #7ed321;
  border-radius: 3px;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
  background-color: #191e22;
  padding: 10px 15px;
  color: #fff;
  font-size: 10px; }
  .hhi .ng-tooltip h3 {
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0 0 5px 0; }
  .hhi .ng-tooltip hr {
    height: 0px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #6d757b; }
  .hhi .ng-tooltip .line {
    width: 725px;
    padding: 3px 0; }
    .hhi .ng-tooltip .line .key {
      display: inline-block;
      width: 75px;
      color: #f5b300;
      float: left; }
      .hhi .ng-tooltip .line .key:after {
        content: ":"; }
    .hhi .ng-tooltip .line .value {
      display: inline-block;
      width: 350px; }
      .hhi .ng-tooltip .line .value .info {
        display: inline-block;
        float: right;
        width: 80px; }
        .hhi .ng-tooltip .line .value .info.info--green {
          color: #7ed321; }
        .hhi .ng-tooltip .line .value .info.info--yellow {
          color: #f8e71c; }

.hhi .ng-tooltip-show {
  opacity: 0.95; }

.hhi .ml-1 {
  margin-left: 10px !important; }

.hhi .ml-2 {
  margin-left: 20px !important; }

.hhi .mt-2 {
  margin-top: 20px !important; }

.hhi .mb-1 {
  margin-bottom: 10px !important; }

.hhi .mb-2 {
  margin-bottom: 20px !important; }

.hhi .mb-3 {
  margin-bottom: 30px !important; }

.hhi .mb-big {
  margin-bottom: 200px !important; }
  @media (max-width: 768px) {
    .hhi .mb-big {
      margin-bottom: 80px !important; } }

.hhi .text-center {
  text-align: center; }

.hhi .color-error {
  color: #d0021b; }

.hhi .nowrap {
  white-space: nowrap; }

.hhi .w150 {
  width: 150px; }

.hhi .brand-title {
  display: inline-block;
  font-size: 31px;
  letter-spacing: 2px;
  color: #3d90c2;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 38px;
  margin: 0;
  vertical-align: top;
  background: -webkit-linear-gradient(to bottom, #fad961, #f76b1c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; }

.hhi .brand-title-description {
  font-size: 20px;
  color: #6a6a6a;
  margin: 5px 0; }

.hhi .dropdown-fog {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 199; }

.hhi .dropdown-container {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .hhi .dropdown-container :first-child {
    cursor: pointer; }
  .hhi .dropdown-container.dropdown--active .dropdown {
    display: block !important; }
  .hhi .dropdown-container .dropdown-button,
  .hhi .dropdown-container .dropdown-opener {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .hhi .dropdown-container .dropdown-button:hover,
    .hhi .dropdown-container .dropdown-opener:hover {
      color: #3d90c2; }
  .hhi .dropdown-container .dropdown {
    position: absolute;
    left: 0;
    top: 30px;
    border: 1px solid #3d90c2;
    background-color: #1f282f;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    display: none;
    z-index: 201;
    min-width: 200px;
    border-top: 0; }
    .hhi .dropdown-container .dropdown.dropdown--right {
      left: auto;
      right: 0;
      margin-right: -26px; }
    .hhi .dropdown-container .dropdown .dropdown-header {
      display: block;
      font-style: italic;
      color: #3d90c2;
      margin: 15px 20px 0 20px; }
    .hhi .dropdown-container .dropdown .dropdown-item {
      margin: 3px 20px; }
    .hhi .dropdown-container .dropdown .dropdown-separator {
      display: block;
      border-bottom: 1px solid #353e46;
      margin: 15px 10px; }
    .hhi .dropdown-container .dropdown .dropdown-button {
      display: block;
      background-color: #2b383e;
      text-align: center;
      font-style: italic;
      text-decoration: none !important;
      padding: 8px 16px;
      margin-top: 15px; }
    .hhi .dropdown-container .dropdown a {
      color: inherit;
      text-decoration: none;
      cursor: pointer; }
      .hhi .dropdown-container .dropdown a:hover {
        text-decoration: underline; }

.hhi #toast-container > div {
  border-radius: 5px;
  opacity: 1;
  box-shadow: none !important; }

.hhi .toast-close-button {
  -webkit-text-shadow: none;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 0.15em;
  top: 0; }
  .hhi .toast-close-button:hover {
    opacity: 0.8; }

.hhi .toast-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px; }

.hhi .toast-message {
  height: 14px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic; }

.hhi body {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.brandLeftLogo {
  position: absolute;
  height: 70px;
  top: 10px;
  left: 10px; }
  @media (max-height: 812px) {
    .brandLeftLogo {
      height: 60px;
      position: static;
      margin-top: 20px; } }

.brandSmallLogo {
  height: 20px;
  margin-left: 8px;
  vertical-align: middle; }

.cultureSwitch {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 20px; }
  .cultureSwitch a {
    margin: 5px 10px;
    color: #999;
    cursor: pointer; }
    .cultureSwitch a.active {
      color: white;
      text-decoration: underline; }
  @media (max-height: 812px) {
    .cultureSwitch {
      position: static; } }

.culture__select-box {
  position: absolute;
  top: 1em;
  right: 1em; }
  .culture__select-box .select-box__option,
  .culture__select-box input:checked + .select-box__input-text {
    display: flex;
    align-items: center; }
  .culture__select-box input:checked + .select-box__input-text span {
    display: none; }
    @media (min-height: 768px) {
      .culture__select-box input:checked + .select-box__input-text span {
        display: inline; } }
  .culture__select-box .select-box__option span,
  .culture__select-box .select-box__value span {
    margin-left: 4px; }

.loaderSpinner {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 2px solid transparent;
  border-top-color: #000000;
  box-shadow: 0 0 5px skyblue;
  border-radius: 50%;
  -webkit-animation: spin9 1.5s ease infinite;
  animation: spin9 1.5s ease infinite; }
  .loaderSpinner::before {
    content: "";
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top-color: #000000;
    box-shadow: 0 0 5px skyblue;
    -webkit-animation: spin9_x 1s linear infinite;
    animation: spin9_x 1s linear infinite; }
  .loaderSpinner::after {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top-color: #000000;
    box-shadow: 0 0 5px skyblue;
    -webkit-animation: spin9 1.5s linear infinite;
    animation: spin9 1.5s linear infinite; }

@-webkit-keyframes spin9 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes spin9 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes spin9_x {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg); } }

@keyframes spin9_x {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg); } }

