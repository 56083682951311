.brand-logo {
    display: inline-block;
    width: 38px;
    height: 38px;
    span {
        display: block;
        background-color: #49afd9;
        border-radius: 3px;
        width: 14px;
        height: 14px;
        margin: 2.5px;
        float: left;
        &:first-child {
            background-color: #37deeb;
        }
    }
}

.brand-icon {
    margin-right: 1rem;
    width: 12rem;
}

.brand-title {
    display: inline-block;
    font-size: 36px;
    letter-spacing: 2px;
    color: rgb(95, 181, 0);
    font-weight: normal;
    text-transform: uppercase;
    line-height: 38px;
    margin: 0;
    vertical-align: top;
    position: relative;
    top: -1px;
}

.brand-title-description {
    font-size: 20px;
    color: #6a6a6a;
    margin: 5px 0;
}