.panel {
    box-sizing: border-box;
    padding: 10px 25px;
    &.panel--collection {
        border-radius: 3px;
        box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
        // border: solid 1px #1f282f;
        // background-color: #1f282f;
        margin-top: 5px;
        >header {
            color: #3d90c2;
            line-height: 20px;
            margin-bottom: 8px;
        }
    }
    // &.panel--uploader {
    //     background-color: #b8b8b8;
    //     color: #14577e;
    //     border-radius: 3px;
    //     padding: 15px 20px;
    //     .panel_container {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         border-radius: 3px;
    //         border: dashed 2px #14577e;
    //         font-size: 16px;
    //         line-height: 19px;
    //         >.panel_button {
    //             width: 100%;
    //             text-align: center;
    //             cursor: pointer;
    //             img {
    //                 margin: 15px;
    //             }
    //             input {
    //                 position: absolute;
    //                 width: 1px;
    //                 height: 1px;
    //                 opacity: 0;
    //             }
    //             &:hover {
    //                 background-color: rgba(0, 0, 0, 0.05);
    //             }
    //         }
    //     }
    // }
    &.panel--uploader {
        color: #fff;
        border-radius: 3px;
        padding: 15px 20px;

        .panel_container {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            border: dashed 2px #14577e;
            font-size: 16px;
            line-height: 19px;

            >.panel_button {
                width: 100%;
                text-align: center;
                cursor: pointer;
                
                input {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    opacity: 0;
                }

                .display-2 {
                    line-height: 168px;
                }
            }
            &:hover {
                background-color: #000;
            }
        }
    }
}