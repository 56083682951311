app-modal {
    display: none;
}

.modal_container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    .modal {
        width: 1024px;
        min-height: 400px;
        margin: 20vh auto;
        border: solid 1px #3d90c2;
        // background-color: #1f282f;
        background-color: #000;
        .modal_header {
            font-size: 30px;
            line-height: 35px;
            font-weight: bold;
            font-style: italic;
            // background-image: linear-gradient(92deg, #8ec5e6, #3d90c2);
            color: #fff;
            padding: 13px 26px;
            // border-top: 5px solid #fff;
        }
        .modal_content {
            padding: 13px 26px;
            &:after {
                display: block;
                content: "";
                clear: both;
            }
        }
        &.modal--small {
            width: 500px;
        }
        .modal_close {
            cursor: pointer;
            display: inline-block;
            float: right;
            font-style: normal;
            padding: 0.5rem 1rem;
            margin-top: -0.5rem;
            margin-right: -1rem;
            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

body.modal-open {
    overflow: hidden;
}