.tooltip__closer {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
}

.ng-tooltip {
    position: fixed;
    max-width: 450px;
    z-index: 10000;
    opacity: 0;
    border: 1px solid #7ed321;
    border-radius: 3px;
    box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
    background-color: #191e22;
    padding: 10px 15px;
    color: #fff;
    font-size: 10px;
    h3 {
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7ed321;
        margin: 0 0 5px 0;
    }
    hr {
        height: 0px;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #6d757b;
    }
    .line {
        width: 725px;
        padding: 3px 0;
        .key {
            display: inline-block;
            width: 75px;
            color: #f5b300;
            float: left;
            &:after {
                content: ":";
            }
        }
        .value {
            display: inline-block;
            width: 350px;
            .info {
                display: inline-block;
                float: right;
                width: 80px;
                &.info--green {
                    color: #7ed321;
                }
                &.info--yellow {
                    color: #f8e71c;
                }
            }
        }
    }
}

// .ng-tooltip:after {
//     content: "";
//     position: absolute;
//     border-style: solid;
// }
// .ng-tooltip-top:after {
//     top: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-color: black transparent transparent transparent;
// }
// .ng-tooltip-bottom:after {
//     bottom: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-color: transparent transparent black transparent;
// }
// .ng-tooltip-left:after {
//     top: 50%;
//     left: 100%;
//     margin-top: -5px;
//     border-width: 5px;
//     border-color: transparent transparent transparent black;
// }
// .ng-tooltip-right:after {
//     top: 50%;
//     right: 100%;
//     margin-top: -5px;
//     border-width: 5px;
//     border-color: transparent black transparent transparent;
// }
.ng-tooltip-show {
    opacity: 0.95;
}