.display-1 {
    display: block;
    text-align: center;
    font-size: 64px;
    line-height: 75px;
    font-weight: 500;
    color: #3d90c2;
    small {
        color: #c0e2f7;
    }
}

.display-2 {
    font-size: 24px;
    line-height: 24px;
}

.text-right {
    text-align: right;
}