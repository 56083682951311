.brand-title {
  display: inline-block;
  font-size: 31px;
  letter-spacing: 2px;
  color: #3d90c2;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 38px;
  margin: 0;
  vertical-align: top;
  background: -webkit-linear-gradient(to bottom, #fad961, #f76b1c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.brand-title-description {
  font-size: 20px;
  color: #6a6a6a;
  margin: 5px 0;
}

