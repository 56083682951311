.badge {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    min-width: 59px;
    height: 16px;
    border-radius: 1.5px;
    border: solid 1px #b8b8b8;
    color: #b8b8b8;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    margin: 0 4px;
    font-style: normal;
    transition: all 0.2s ease-in-out;
    &.badge--gray {
        background-color: #4a4a4a;
        color: #fff;
        border: 0;
    }
    &.badge--orange {
        background-color: #e29d28;
        color: #fff;
        border: 0;
    }
    &.badge--blue {
        background-color: #3d90c2;
        color: #fff;
        border: 0;
    }
    &.badge--green {
        background-color: #8bc34a;
        color: #fff;
        border: 0;
    }
    &.badge--red {
        background-color: #800303;
        color: #fff;
        border: 0;
    }
    &[data-index]:after {
        content: attr(data-index);
        display: inline-block;
        position: absolute;
        min-width: 12px;
        height: 12px;
        border-radius: 1.5px;
        background-color: #d0021b;
        right: -4px;
        top: -4px;
        line-height: 12px;
        font-size: 9px;
    }
}