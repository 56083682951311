$toaster-error: #c8051d;
$toaster-success: #8bc34a;
$toaster-warning: #f5b300;

$toaster-icon-success: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAuFJREFUOBGdVU1IVFEUPue+pxm2cWW5KqNWFRnGmPMGF65ydJBIpCJoE0KlZfMsaCWt+hmtlH4gkEhaJPSnM7MKN85YtIwIIhT6m2wRRYKlzdzTuVP39d5z1MG7ueeec77v3Pede+9DWGYMpiJVvyC3FwGqAWE9AM0CiIwhRPpU3a5niL1yKThjFo+BdOvmefn7FiI1EjFlgYGIX5DgXDSUGCoQ9oKICGPpluNI8gIBlBcCLPIhJsvAONppjWbcMWc3A2871yzMTCcIqNGdUJyN30CI/T3BsXGdL7SxMDN1fnWkioEqgOTwjYlwhebLE19JN+8hRFs7VzUTVc0hDGqs6J9sW5uT8g4QObvXwZVmBHwBwDL8G9yjQ7HJlla1FCR/RrhRW3Ww2BkF9tmhRMAEqAXE9w4uJ3uULbhKvoITKMZAjNnBRF667lBi2jSMgxpGCHVXn0cqBR+4ndqZnxGmBIown9MRj18vmLTHSuR3pV3ZXLZe20rSbBZ2sK5UpZ189j7wrWqIWvFkNLj7AIIY1jE1I4rLftK+VFMH4y568khuEEBgOE7E8dP18U9/SXpl1Ko9oskZfMm24mecXDYUKffn5uLbSSZLgZ91cr6rqfA+vVZvgSJnudrtUPKs9qt5aVL+MoMyGEuFnzBhxAXKsr7ttpV46PJ5zOVIVSIK2Cj4U0Y9KACTC93ngs7O3fGVSDn3pR1MvhNmeckD9VK5wWwXJC+ClFXD64pLdNc8/i6QOnzEaukhL4aURXjKJ+q2AjuvW2yi+S6BPKycnoEoOek1y7PN4/ctEOFHCRjbT1pj+VvovA/GOrOLy7zx5fMxJ3U7lyVlTJY//pgmVRwOsZLEEOU13NFrXJ17Wtzg/rwyTSPAZ/yeG+FI4Xb2T7Y0yJwc4ve52u332XwsRay0clNv15bBeV/sv8b+wAi1GR/Tc/USsIn1UAX4ZwqzfMsy/EXpspLS+InAo69+nF7/AZKNGA91N1heAAAAAElFTkSuQmCC";
$toaster-icon-error: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAsFJREFUOBGVVU1oE1EQnnlZ07QRbOjBGmyNNepJ0VJM6kEPnupBPFgvCooiHgRPiuCpeNGDNxEFoQjFowd/T9KDUGyLVKjiDxZ0G5tSwRpbbONu+sZ527x032Y34mOXmfnmm3nz5u17i9BgTLR0pF1X9AFCF4BsJ8JFRCiKGIz0XDnzCgcGZFQ4hjleN3Vsq0i8i0CHCDht6MA5dl3Nu/ZgmNsIIi5pPJG5AFLe4ITJsIA6DPF53KJz3UuFot9XS/w5m22at51nxFX6CUpHwBLj3rJZX8963OTgT4zhsVzZHta40Mr8tHMtIulwzp1O5d1Cm3oJ6biOWZOUIklDkxs6UxrzEo81b+kFoksabCQRYrVVGjyi9PIy3NKYKGzubYYVeZ97WqteO5VkvMlvI6wYtt/He3RiLJ45qjAx+/3bESLY4Sf4dS7PSMT7a9h+rqeTvKyk4B3xZqgjVAHuqZGIV5CI4q7ilH+b3LqRl497GhGDFaMwVxCMVS0tV1Z2CyRIB51+m8BcetD2c7UuJWziiimmgTAZrFjQv1qhNhwtQQizYQk1xhv7nz3mXggsWtzj9zxHVieql5QaW9f5ko9fyfMRHajnmIiF+IF7jI9NOMRC+s3owupLTghjDUKY7C5/sUU8iQ+5ar6pwoe6J3JOoS/nTJ9ULyLeCWdWUcLbShN7S19LgHQ+iqwvH+3nz4mfqIEvco59T3m9Y5x3Co+4kqEwOldsUX9/7cvhrMZm6hjmLViWdZbzeBPXLpQ3rZlWZ0mO8nnfqclaMvkT47+q9i6WzdpXlRWB4vQ+x36g8VpiBagLaWZu5jr/OS7ytIZPBwQl/6rexUic6nHtCb8vNJj/IgdJykGusstPDugVQLzZ1hkf2D419Sfgi65K9XX8yeh+kniYZ+/i+7qdEy3ygSqihBFItDzNLX78EUyo7b9unfDq5eQtHAAAAABJRU5ErkJggg==";
$toaster-icon-warning: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAopJREFUOBGlVU1oE0EYfTO7TbIJgsWDGm8K6kkEL+pBkYq2SRUVL0VBQcSDohd70IvFi4LeRBCEXoqIoFBtEkGKnqqeRPy9eBGlUsS2WNNtJNnxzZYJk2RSofkOmW/ee9/b2fkmswJLRPkZsvIv+iJgPWVrhMAcIkzCw0TQh1eck3KHcMELJWyoRbhDskcpODWsm5ICl4N+DLs8Goq0SVjAWVpdZ54xBRQ94Oqm9VwBB8mtMxy1JSRwOrOPb2JF3ViVkAxrKLKwx+LBwulMP1YZrDyGIeZXzDweBWakxNEgh+cGlyYJFa62mBryf6NCt6phRBXQbaS+TuafYgequGhAe+QrrQjHcEx4i1sRRdjFrWgJQtkQuEXiuCaFeokgnMZbije2qJcDeDicyWHUD2fjZjhN2bCHfOBH25/YwFKLEBEGqR/1eRIP2YV2Ln1cSvXii42xeToam2cLFLarcayWPD9bbbzTnHstKxVskUog284squHcfBED7fh2uIqw1mfXPUeT4xoKLuhzzMn9diZOXMDXW/HDSXYAssGTkiv61IGHs5Q78Jn3CJ442eWD74I8vspUBo/oMeXy4UOPpCU221w6gRtI8hIS+GDj9Vzgts6l2INZSJypE1Yiu/Be5PDTgiD2oxzfZArfbDzOBcbTedzVeXwJZfJ4zNWNNAuJJZsxM2/hBH7LJE6xcfEhI78Y6gVWhn/wmugmg1H0nXnDPas5ajz+bLN0VaFwMjiAe3XMJHqML6QZXGPRed4H9Yfampace93l40SiF29szlkclrCbf45hmutvnTP4NlUu4GbgYYh9qDSLnMZaRFNvoYidHHNce/wx5RbMkeLhx0QqhYLYi1/Nhmb+D1lWudxg7bHPAAAAAElFTkSuQmCC";



  #toast-container > div  {
    border-radius: 5px;
    opacity: 1;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .toast-close-button {
    -webkit-text-shadow: none;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: 0.15em;
    top: 0;

    &:hover {
        opacity: 0.8;
    }
  }

  .toast-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .toast-message {
    height: 14px;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
  }