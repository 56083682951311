.collection {
    border-radius: 3px;
    box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px rgb(61, 144, 194);
    background-color: #000;
    margin-top: 10px;
    >header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: #3d90c2;
        height: 40px;
        padding: 8px 8px;
        // margin: 0 8px;
        line-height: 24px;
        &:hover {
            background-color: #1f282f;
        }
    }
    .collection_info {
        font-size: 15px;
        color: #979797;
        margin: 0 10px;
    }
    .collection_content {
        display: none;
        padding: 8px 0;
        margin: 0 8px;
    }
    .collection_actions {
        display: inline-block;
        width: 284px;
        text-align: right;
    }
    .collection_toggle {
        vertical-align: middle;
        margin: 0 4px 0 12px;
        cursor: pointer;
        &.collection_toggle--opened {
            display: none;
        }
        &.collection_toggle--closed {
            display: inline-block;
        }
    }
    &.collection--active {
        >header {
            border-bottom: .5px solid #979797;
        }
        .collection_content {
            display: block;
        }
        .collection_toggle {
            &.collection_toggle--opened {
                display: inline-block;
            }
            &.collection_toggle--closed {
                display: none;
            }
        }
    }
    &:first-child {
        margin-top: 0;
    }
}