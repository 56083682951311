.cards {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    .card {
        display: block;
        border-radius: 3px;
        box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, 0.5);
        background-color: #000;
        padding: 25px;
        width: calc(50% - 12.5px);
        float: left;
        margin-bottom: 30px;
        cursor: pointer;
        &:nth-child(2n) {
            margin-left: 25px;
        }
        .card_title {
            color: #3d90c2;
            margin: 0;
            font-size: 20px;
            line-height: 24px;
        }
        &:hover {
            box-shadow: none;
            outline: 1px solid #3d90c2;
        }
    }
}