.select-box {
    position: relative;
    display: block;

    &__current {
        position: relative;
        cursor: pointer;
        outline: none;
        padding-right: 1.25em;

        &:focus {
            + .select-box__list {
                opacity: 1;
                animation-name: none;

                .select-box__option {
                    cursor: pointer;
                }
            }

            .select-box__icon {
                transform: rotate(180deg);
            }
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 0px;
        width: 0;
        height: 0;
        border-left: 0.35em solid transparent;
        border-right: 0.35em solid transparent;
        border-top: 0.35em solid currentColor;
        margin-top: -0.175em;
    }

    &__value {
        display: flex;
        user-select: none;
    }

    &__input {
        display: none;

        &:checked + .select-box__input-text {
            display: block;
        }
    }

    &__input-text {
        display: none;
        width: 100%;
        margin: 0;
    }

    &__list {
        position: absolute;
        padding: 0;
        list-style: none;
        opacity: 0;
        animation-name: SelectBoxHideList;
        animation-duration: 0.5s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: step-start;
        z-index: 10000;
        background-color: black;
        white-space: nowrap;
        max-height: 100vh;
        overflow-y: auto;
    }

    &__option {
        display: block;
        padding: 0.375em 0.75em;

        &:hover,
        &:focus {
            color: #546c84;
            background-color: #fbfbfb;
        }

        &--active {
            color: black;
            background-color: white;
        }
    }

    &--left {
        > .select-box__list {
            left: 0;
        }
    }
    &--right {
        > .select-box__list {
            right: 0;
        }
    }
}

@keyframes SelectBoxHideList {
    from {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}
