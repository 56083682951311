div.form,
form.form {
    .btn:hover {
        filter: brightness(0.7);
    }
    font-size: 16px;
    h3.title {
        color: #fff;
        font-size: 20px;
        line-height: 24px;
        font-weight: normal;
        margin: 30px 0;
        text-align: center;
        text-transform: uppercase;
    }
    h4.title {
        color: #3d90c2;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
    }
    .form-group {
        font-size: 14px;
        margin-bottom: 15px;
        label {
            display: block;
            font-size: 14px;
            line-height: 16px;
            color: #6a6a6a;
            margin-bottom: 5px;
        }
        input {
            display: block;
            width: 100%;
            line-height: 37px;
            color: #fff;
            font-size: 16px;
            box-sizing: border-box;
            padding: 10px 20px;
            width: 335px;
            height: 61px;
            border-radius: 3px;
            border: solid 1px #6a6a67;
            background-color: #191e22;
            max-width: 100%;
        }
        span,
        small {
            display: block;
            margin-top: 5px;
        }
    }
    .rememberMe {
        color: #6a6a6a;
        font-size: 16px;
        letter-spacing: normal;
    }
    .btn {
        padding: 10px 20px;
        line-height: 30px;
        border-radius: 3px;
        text-transform: uppercase;
        text-align: center;
        line-height: 30px;
        border: 0;
        width: 335px;
        height: 61px;
        background-image: linear-gradient(to bottom, #fad961, #f76b1c);
        font-size: 16px;
        color: black;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.btn-secondary {
            background: transparent;
            border: solid 1px #f5b300;
            background-color: #191e22;
            color: #f5b300;
            line-height: 40px;
        }
    }
    hr {
        background: none;
        border: 0;
        border-bottom: 1px dashed #6a6a6a;
        margin-top: 65px;
        margin-bottom: 21px;
        &.distance {
            margin-top: 30px;
        }
    }
    .link {
        font-size: 16px;
        color: #6a6a6a;
        text-decoration: none;
        &:hover {
            border-bottom: 0;
            text-decoration: underline;
        }
        &.hoverable:hover {
            color: #3d90c2;
            text-decoration: underline;
        }
    }
    .error {
        color: #d0021b !important;
        a {
            color: #d0021b !important;
        }
        input,
        select,
        textarea {
            border-color: #d0021b;
        }
    }
    .description {
        margin: 30px 0;
        color: #6a6a6a;
        ul,
        ol {
            padding-left: 0;
            font-size: 14px;
            li {
                list-style-position: inside;
                margin: 5px;
            }
        }
    }
    footer {
        font-size: 12px;
        color: #6a6a6a;
        p {
            margin-bottom: 1px;
            margin-top: 2px;
            text-decoration: none;
        }
        p {
            a {
                color: #f5b300;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        a {
            color: #f5b300;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}