.progress {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 100px;
    height: 10px;
    border-radius: 1.5px;
    border: solid 1px #fff;
    .progress_handler {
        position: absolute;
        display: block;
        background-color: #fff;
        top: 0;
        left: 0;
        height: 100%;
    }
    &.progress--green {
        border-color: #7ed321;
        .progress_handler {
            background-color: #7ed321;
        }
    }
    &.progress--red {
        border-color: #d0021b;
        .progress_handler {
            background-color: #d0021b;
        }
    }
}