$sidebar--background-color: #1f282f;
$sidebar--distance-from-side: 200px;
.sidebar {
    position: fixed;
    top: 60px;
    bottom: 60px;
    border: 1px solid #3d90c2;
    background-color: $sidebar--background-color;
    width: calc(100% - #{$sidebar--distance-from-side});
    transition: all 0.2s ease-in-out;
    z-index: 101;
    .sidebar_handler {
        position: absolute;
        left: 0;
        margin-top: 100px;
        cursor: pointer;
        >div {
            padding: 25px 0;
        }
        span {
            display: inline-block;
            word-wrap: break-word;
            box-sizing: border-box;
            padding: 10px;
            width: 25px;
            background-color: $sidebar--background-color;
        }
        &:hover {
            span {
                color: #3d90c2;
            }
        }
    }
    .sidebar_content {
        box-sizing: border-box;
        padding: 20px;
        height: 100%;
        overflow-y: auto;
    }
    &.sidebar--right {
        border-right-width: 0;
        left: 100%;
        min-width: 5px;
        margin-left: -5px;
        .sidebar_handler {
            margin-left: -25px;
            i {
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: transparent;
                }
            }
            i:first-child {
                &:before,
                &:after {
                    top: 0;
                    border-width: 0 0 25px 25px;
                }
                &:before {
                    border-bottom-color: #3d90c2;
                    margin-top: -1px;
                }
                &:after {
                    border-bottom-color: $sidebar--background-color;
                }
            }
            i:last-child {
                &:before,
                &:after {
                    bottom: 0;
                    border-width: 0 25px 25px 0;
                }
                &:before {
                    border-right-color: #3d90c2;
                }
                &:after {
                    border-right-color: $sidebar--background-color;
                    left: 1px;
                }
            }
            span {
                border-left: 1px solid #3d90c2;
                margin-top: -1px;
            }
        }
    }
    &.sidebar--active {
        left: $sidebar--distance-from-side;
        margin-left: 0;
    }
}

.sidebar-back {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

body.sidebar-open {
    overflow: hidden;
}