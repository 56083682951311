.dropdown-fog {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 199;
}

.dropdown-container {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    :first-child {
        cursor: pointer;
    }

    &.dropdown--active {
        .dropdown {
            display: block !important;
        }
    }

    .dropdown-button,
    .dropdown-opener {
        user-select: none;
        &:hover {
            color: #3d90c2;
        }
    }

    .dropdown {
        position: absolute;
        left: 0;
        top: 30px;
        border: 1px solid #3d90c2;
        background-color: #1f282f;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 14px;
        white-space: nowrap;
        display: none;
        z-index: 201;
        min-width: 200px;
        border-top: 0;

        &.dropdown--right {
            left: auto;
            right: 0;
            margin-right: -26px;
        }

        .dropdown-header{
            display: block;
            font-style: italic;
            color: #3d90c2;
            margin: 15px 20px 0 20px;
        }
        .dropdown-item {
            margin: 3px 20px;
        }

        .dropdown-separator{
            display: block;
            border-bottom: 1px solid #353e46;
            margin: 15px 10px;
        }

        .dropdown-button {
            display: block;
            background-color: #2b383e;
            text-align: center;
            font-style: italic;
            text-decoration: none !important;
            padding: 8px 16px;
            margin-top: 15px;
        }

        a{
            color: inherit;
            text-decoration: none;
            
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}