.btn {
    display: inline-block;
    border: solid 1px #b8b8b8;
    color: #b8b8b8;
    border-radius: 2px;
    // padding: 0 8px;
    padding: 6px 10px;
    line-height: 1;
    font-style: normal;
    white-space: nowrap;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        margin-left: 8px;
        &:first-child {
            margin-left: 0;
        }
    }
    .icon:before {
        vertical-align: middle;
    }
    &:hover {
        border-color: #fff;
        color: #fff;
    }
    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
    &.btn--green {
        color: #7ed321;
        border-color: #7ed321;
        &:hover {
            background-color: #8bc34a;
            border: solid 1px #7ed321;
            color: #3c763d;
        }
        &:disabled {
            border-color: #fff;
            color: #fff;
            &:hover {
                background-color: transparent;
            }
        }
    }
    &.btn--transparent {
        border-color: transparent;
    }
    &.btn--lg {
        border-radius: 3px;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        padding: 15px 30px;
        min-width: 184px;
        &:disabled {
            border: solid 1px #b8b8b8 !important;
            color: #b8b8b8 !important;
            background-color: transparent !important;
        }
    }
    &.btn--sm {
        padding: 2px 4px;
    }
}

.link {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    text-transform: none;
    color: #fff;
    .icon {
        font-size: 75%;
        margin-left: 5px;
    }
    &:hover {
        border-bottom: 1px solid #fff;
    }
    &.link--green {
        color: #7ed321;
        &:hover {
            border-color: #7ed321;
        }
    }
    &.link--gray {
        color: #b8b8b8;
        &:hover {
            border-color: #b8b8b8;
        }
    }
}