html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: auto;
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background-color: #191e22;
    color: #fff;
    font-size: 15px;
}

* {
    box-sizing: border-box;
}

.container {
    box-sizing: border-box;
    width: 1024px;
    margin: 0 auto;
    margin-bottom: 25px;
    padding: 0;
    &.container-small {
        width: 335px;
        >div {
            width: 100%;
        }
    }
    &.container-medium {
        width: 720px;
        >div {
            width: 100%;
        }
    }
    &.container-middle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        box-sizing: border-box;
        width: 768px;
        max-width: 100%;
        background-color: rgba(25, 30, 34, 0.9);
        >div {
            width: 335px;
            position: relative;
        }
    }
    .contentWrapper {
        padding: 50px 0;
    }
    >div {
        overflow: unset;
        height: 100%;
        margin: auto;
    }
    @media (max-width: 768px) {
        position: fixed;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        &.container-middle {
            height: 100%;
        }
        >div {
            width: 335px;
            max-width: 100%;
            max-height: 100%;
            padding: 0 .5em;
        }
    }
}