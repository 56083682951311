.ml-1 {
    margin-left: 10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mb-big {
    margin-bottom: 200px !important;
    @media (max-width: 768px) {
        margin-bottom: 80px !important;
    }
}

.text-center {
    text-align: center;
}

.color-error {
    color: #d0021b;
}

.nowrap {
    white-space: nowrap;
}

.w150 {
    width: 150px;
}